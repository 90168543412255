import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FaPhoneAlt, FaUser } from "react-icons/fa";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import PlaceHolder from "../../../assets/images/Placeholder.png";
import MakeOfferModal from "../modals/MakeOfferModal";
import LcaCardQuestions from "./LcaCardsQuestions";
import LcaCardsEvents from "./LcaCardsEvents";
import "./LcaCards.css"; // Import your custom CSS file
import moment from "moment-timezone";
import "moment-timezone/builds/moment-timezone-with-data";
import { GetZoomLink, myOffers } from "../Data2";
import CarouselComponent from "./BizzList";
import { initiateCall, makeCall, sendMsgMail } from "../Data";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardText,
  Button,
} from "reactstrap";
import { setSelectedAd } from "../../../redux/slices/LcaSlice";
import { MdGroups } from "react-icons/md";

const LcaBox = ({ ad, isMsgModalOpen, setIsMsgModalOpen }) => {
  const dispatch = useDispatch();
  const islogin = useSelector((state) => state.user.isLogin);
  const userdata = useSelector((state) => state.user);
  const userinfo = useSelector((state) => state.user.userInfo);
  const user = islogin ? JSON.parse(userinfo) : null;
  const [isOfferModalOpen, setIsOfferModalOpen] = useState(false);
  const [schedule, setSchedule] = useState(false);
  const navigate = useNavigate();
  const [questionsVisible, setQuestionsVisible] = useState({});
  const [eventsVisible, setEventsVisible] = useState({});
  const [imageIndex, setImageIndex] = useState({});
  const { myOffers, selectedAd } = useSelector((state) => state.lcAds);
  const isAdInMyOffers = (adId) => {
    const offer = myOffers && myOffers.some((offer) => offer.Id === adId);
    return offer;
  };
  const handleCardClick = (selectedCard) => {
    const screenWidth = window.innerWidth;
    const isIpadPro = screenWidth === 1024; // Check for iPad Pro screen width

    if (!isMsgModalOpen && !isOfferModalOpen) {
      if (
        selectedCard.engagedProfile === 1 ||
        selectedCard.engagedCouple === 1
      ) {
        if (islogin) {
          if (isIpadPro) {
            navigate(`/engagedCouple/${selectedCard.createdBy}`);
          } else {
            window.open(`#/engagedCouple/${selectedCard.createdBy}`, "_blank");
          }
        } else {
          // window.open("#/login", "_blank");
          toast.info("Please Login To View Details..");
        }
      } else {
        if (islogin) {
          if (isIpadPro) {
            navigate(`/requestDetails/${selectedCard.Id}`);
          } else {
            window.open(`#/requestDetails/${selectedCard.Id}`, "_blank");
          }
        } else {
          //   navigate("/login");
          toast.info("Please Login To View Details..");
        }
      }
    }
  };
  const closeModal = () => {
    setIsMsgModalOpen(false);
    setIsOfferModalOpen(false);
    dispatch(setSelectedAd(null));
    setSchedule(false);
  };
  const openOfferModal = (event, ad) => {
    event.stopPropagation(); // Stop event propagation here
    if (!islogin) {
      toast.info("Please Login To Get Customer!..");
    } else {
      setIsOfferModalOpen(true);
      dispatch(setSelectedAd(ad));
    }
  };

  function formatDate1(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");

    return `${year}-${month}-${day} ${hours}:${minutes}`;
  }
  const now = new Date();
  const formattedDate = formatDate1(now);
  const handleGroupCall = async (ad) => {
    const ID = ad?.Id;
    const phoneDetailsObj = [
      {
        email: ad?.Email,
        phoneNumber: ad?.Phone,
        countryCode: ad?.countryCode,
      },
      {
        email: "",
        phoneNumber:
          ad?.alternativePhoneOne === null || ad?.alternativePhoneOne === ""
            ? ""
            : ad?.alternativePhoneOne,
        countryCode:
          ad?.alternativePhoneOne === null || ad?.alternativePhoneOne === ""
            ? ""
            : ad?.countryCode,
      },
      {
        email: "",
        phoneNumber:
          ad?.alternativePhoneTwo === null || ad?.alternativePhoneTwo === ""
            ? ""
            : ad?.alternativePhoneTwo,
        countryCode:
          ad?.alternativePhoneTwo === null || ad?.alternativePhoneTwo === ""
            ? ""
            : ad?.countryCode,
      },
      {
        email: user?.Email,
        phoneNumber: user?.Phone,
        countryCode: user?.CountryCode,
      },
    ];
    const phoneDetails = JSON.stringify(phoneDetailsObj);
    let formData = {
      phoneDetails: phoneDetails,
    };
    console.log(phoneDetailsObj, "phoneDetailsObj");
    toast.success("Group Call Started");
    await initiateCall(formData);
    // const response = await makeCall(formData);
    // toast.success("Call started and Zoom link sent");
    // if (response === "initiating call") {
    //   const formData = {
    //     startTime: formattedDate,
    //     needName: ad?.Title,
    //   };
    //   const linkResponse = await GetZoomLink(formData, ad?.Id);
    //   let formData1 = {
    //     zoomlink: linkResponse.join_url,
    //     phoneDetails: phoneDetails,
    //   };
    //   await sendMsgMail(formData1);
    // } else {
    //   throw new Error("Response from makeCall was invalid");
    // }
  };
  const handleIndiviualCall = async (ad) => {
    const ID = ad?.Id;
    const phoneDetailsObj = [
      {
        email: ad?.Email,
        phoneNumber: ad?.Phone,
        countryCode: ad?.countryCode,
      },
      {
        email: user?.Email,
        phoneNumber: user?.Phone,
        countryCode: user?.CountryCode,
      },
    ];
    const phoneDetails = JSON.stringify(phoneDetailsObj);
    let formData = {
      phoneDetails: phoneDetails,
    };
    console.log(phoneDetailsObj, "phoneDetailsObj");
    toast.success("Call Started");
    await initiateCall(formData);
    // const response = await makeCall(formData);
    // toast.success("Call started and Zoom link sent");
    // if (response === "initiating call") {
    //   const formData = {
    //     startTime: formattedDate,
    //     needName: ad?.Title,
    //   };
    //   const linkResponse = await GetZoomLink(formData, ad?.Id);
    //   let formData1 = {
    //     zoomlink: linkResponse.join_url,
    //     phoneDetails: phoneDetails,
    //   };
    //   await sendMsgMail(formData1);
    // } else {
    //   throw new Error("Response from makeCall was invalid");
    // }
  };
  const toggleQuestions = (event, ad) => {
    event.stopPropagation();
    const currentQuestionsVisible = questionsVisible[ad.Id] || false;
    const currentEventsVisible = eventsVisible[ad.Id] || false;
    if (currentEventsVisible) {
      setEventsVisible({ ...eventsVisible, [ad.Id]: false });
    }
    const updatedQuestionsVisible = {};
    Object.keys(questionsVisible).forEach((cardId) => {
      updatedQuestionsVisible[cardId] =
        cardId === ad.Id ? !currentQuestionsVisible : false;
    });
    setQuestionsVisible({
      ...updatedQuestionsVisible,
      [ad.Id]: !currentQuestionsVisible,
    });
  };
  function convertTo12HourFormat(time24) {
    if (!time24) {
      return "";
    }
    const [hour24, minute] = time24.split(":").map(Number);
    if (isNaN(hour24) || isNaN(minute)) {
      return "";
    }
    const period = hour24 >= 12 ? "PM" : "AM";
    const hour12 = (hour24 % 12 || 12).toString().padStart(2, "0"); // Added padStart
    return `${hour12}:${minute.toString().padStart(2, "0")} ${period}`;
  }

  function formatDate(inputDate) {
    let date = new Date(inputDate);
    let monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    let year = date.getFullYear();
    let month = monthNames[date.getMonth()];
    let day = date.getDate();
    let formattedDay = day < 10 ? "0" + day : day;
    let formattedDate = `${month} ${formattedDay}, ${year}`;
    return formattedDate;
  }

  const toggleEvents = (event, ad) => {
    event.stopPropagation();
    const currentQuestionsVisible = questionsVisible[ad.Id] || false;
    const currentEventsVisible = eventsVisible[ad.Id] || false;
    if (currentQuestionsVisible) {
      setQuestionsVisible({ ...questionsVisible, [ad.Id]: false });
    }
    const updatedEventsVisible = {};
    Object.keys(eventsVisible).forEach((cardId) => {
      updatedEventsVisible[cardId] =
        cardId === ad.Id ? !currentEventsVisible : false;
    });
    setEventsVisible({
      ...updatedEventsVisible,
      [ad.Id]: !currentEventsVisible,
    });
  };

  const openMsgModal = (event, ad) => {
    event.stopPropagation();
    if (!islogin) {
      toast.info("Please Login To Send Message..");
    } else {
      setIsMsgModalOpen(true);
      dispatch(setSelectedAd(ad));
    }
  };

  function convertToUserTimezone(utcTime) {
    let timezone = "America/Los_Angeles"; // Default to system timezone
    if (islogin && user?.TimeZone) {
      timezone = user?.TimeZone.split(",")[0];
    }
    const localTime = moment
      .utc(utcTime)
      .tz(timezone)
      .format("YYYY-MM-DD HH:mm:ss");
    return localTime;
  }

  const handleNextImage = (event, ad) => {
    event.stopPropagation();
    const currentIndex = imageIndex[ad.Id] || 0;
    const images = JSON.parse(ad.Photo);
    const nextIndex = (currentIndex + 1) % images.length;
    setImageIndex({ ...imageIndex, [ad.Id]: nextIndex });
  };

  const handlePrevImage = (event, ad) => {
    event.stopPropagation();
    const currentIndex = imageIndex[ad.Id] || 0;
    const images = JSON.parse(ad.Photo);
    const prevIndex = (currentIndex - 1 + images.length) % images.length;
    setImageIndex({ ...imageIndex, [ad.Id]: prevIndex });
  };
  return (
    <>
      {" "}
      <Card className="shadow-sm position-realtive">
        <Row className="g-0">
          <div
            className="position-absolute"
            style={{
              backgroundColor: "white",
              width: "auto",
              padding: "0 3px 3px 0",
            }}
          >
            <img
              className=""
              style={{
                width: "20px",
              }}
              src={ad?.profilePhoto ? ad?.profilePhoto : PlaceHolder}
              alt={ad?.firstName}
            />
          </div>
          <Col lg="3" md="3" className="d-flex align-items-center ">
            <div className="position-relative">
              {ad.Photo && JSON.parse(ad.Photo).length > 1 && (
                <button
                  className="arrow left-arrow position-absolute"
                  style={{
                    top: "50%",
                    left: "3px",
                    transform: "translateY(-50%)",
                    backgroundColor: "rgba(255, 255, 255, 0.5)",
                    border: "none",
                    fontSize: "30px",
                    zIndex: 1,
                  }}
                  onClick={(event) => handlePrevImage(event, ad)}
                >
                  &#10094;
                </button>
              )}
            </div>
            <img
              className="wc_egcpl-ad img-fluid"
              src={
                ad.Photo && JSON.parse(ad.Photo).length > 0
                  ? JSON.parse(ad.Photo)[imageIndex[ad.Id] || 0]
                  : PlaceHolder
              }
              alt={ad.Title}
              style={{
                objectFit: "fill",
                height: "220px",
                width: "220px",
              }}
            />
            <div className="position-relative">
              {ad.Photo && JSON.parse(ad.Photo).length > 1 && (
                <button
                  className="arrow right-arrow position-absolute"
                  style={{
                    top: "50%",
                    right: "3px",
                    transform: "translateY(-50%)",
                    backgroundColor: "rgba(255, 255, 255, 0.5)",
                    border: "none",
                    fontSize: "30px",
                    zIndex: 1,
                  }}
                  onClick={(event) => handleNextImage(event, ad)}
                >
                  &#10095;
                </button>
              )}
            </div>
          </Col>
          <Col lg="7" md="7">
            <CardBody
              onClick={() => handleCardClick(ad)}
              className="lca-card-body"
            >
              <CardTitle
                tag="h5"
                className="mb-2"
                style={{
                  fontSize: "large",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                <b>
                  {" "}
                  {ad.Title.length > 100
                    ? `${ad.Title.substring(0, 100)}...`
                    : ad.Title}
                </b>
              </CardTitle>
              <CardText
                style={{
                  fontSize: "medium",
                  // overflow: "hidden",
                  // textOverflow: "ellipsis",
                  // whiteSpace: "nowrap",
                  marginLeft: 0,
                }}
              >
                {ad.Description}
              </CardText>
              <div className="d-lg-flex">
                <CardText
                  className="text-nowrap mx-0"
                  style={{ fontSize: "medium" }}
                >
                  <FontAwesomeIcon icon={faMapMarkerAlt} />
                  <span> {ad.Zipcode}</span> -{" "}
                  <span className=""> {ad.placeName}</span>
                </CardText>
                {ad.categoryName && (
                  <CardText className="" style={{ fontSize: "medium" }}>
                    <b>Service Needed:</b>{" "}
                    {ad.itemName
                      ? ad.itemName
                      : ad.subCategoryName
                      ? ad.subCategoryName
                      : ad.categoryName
                      ? ad.categoryName
                      : ""}
                  </CardText>
                )}
              </div>
              <div>
                <p className="mb-0 posting" style={{ fontSize: "small" }}>
                  Posting Date/Time:{" "}
                  {formatDate(
                    convertToUserTimezone(ad?.createdAt).split(" ")[0]
                  )}
                  &nbsp;
                  {convertTo12HourFormat(
                    convertToUserTimezone(ad?.createdAt).split(" ")[1]
                  )}
                </p>
              </div>
            </CardBody>
          </Col>
          <Col
            lg="2"
            md="2"
            className="d-flex flex-column align-items-center justify-content-center"
          >
            {ad.engagedCouple == 1 && (
              <div className="w-100 text-center engagedCouple">
                {isAdInMyOffers(ad.Id) ? (
                  <>
                    <span className="dropdown-center">
                      <Button
                        color="success"
                        size="sm"
                        className="dropdown-toggle w-100 mb-2"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        Call Me
                      </Button>
                      <ul
                        className="dropdown-menu dropdown-menu-center"
                        style={{ cursor: "pointer" }}
                      >
                        <li>
                          <a
                            class="dropdown-item text-center fw-bold"
                            onClick={() => handleIndiviualCall(ad)}
                          >
                            Individual Call <FaUser />
                          </a>
                        </li>
                        <li>
                          <a
                            class="dropdown-item text-center fw-bold"
                            onClick={() => handleGroupCall(ad)}
                          >
                            Group Call <MdGroups size={20} />
                          </a>
                        </li>
                      </ul>
                    </span>
                    <Button
                      color="primary"
                      size="sm"
                      className="w-100 mb-2"
                      // onClick={(event) => openOfferModal(event, ad)}
                      onClick={(event) => openMsgModal(event, ad)}
                    >
                      Send message
                    </Button>
                  </>
                ) : (
                  <Button
                    color="primary"
                    size="sm"
                    className="w-100 mb-2"
                    onClick={(event) => openOfferModal(event, ad)}
                  >
                    Get Customer!
                  </Button>
                )}
                <Button
                  color={questionsVisible[ad.Id] ? "secondary" : "warning"}
                  size="sm"
                  className="w-100 mb-2"
                  onClick={(event) => toggleQuestions(event, ad)}
                >
                  {questionsVisible[ad.Id] ? "Hide Q&A" : "Show Q&A"}
                </Button>
                <Button
                  color={eventsVisible[ad.Id] ? "secondary" : "success"}
                  size="sm"
                  className="w-100"
                  onClick={(event) => toggleEvents(event, ad)}
                >
                  {eventsVisible[ad.Id]
                    ? "Hide Event Details"
                    : "Show Event Details"}
                </Button>
              </div>
            )}
            {ad.engagedCouple != 1 && (
              <div className="w-100 text-center need">
                <a
                  href={`tel: 8002453610`}
                  className="btn btn-success btn-sm mb-2 w-100"
                >
                  <FaPhoneAlt /> (800)245-3610 <br />
                  <span>Ext# {ad.dialExtension}</span>
                </a>
                <Button
                  color="primary"
                  size="sm"
                  className="w-100"
                  onClick={(event) => openMsgModal(event, ad)}
                >
                  Send Message
                </Button>
              </div>
            )}
          </Col>
        </Row>

        {questionsVisible[ad.Id] && (
          <CardBody>
            <LcaCardQuestions ad={ad} />
          </CardBody>
        )}
        {eventsVisible[ad.Id] && (
          <CardBody>
            <LcaCardsEvents ad={ad} />
          </CardBody>
        )}
      </Card>
      <MakeOfferModal
        isOpen={isOfferModalOpen}
        closeModal={closeModal}
        selectedCard={selectedAd}
        setSchedule={setSchedule}
        schedule={schedule}
      />
      {/* <MsgModal
        isOpen={isMsgModalOpen}
        closeModal={closeModal}
        selectedCard={selectedAd}
      /> */}
    </>
  );
};

export default LcaBox;
