import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  Button,
  Col,
  FormGroup,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Row,
} from "reactstrap";
import Select from "react-select";
import { useLocation } from "react-router-dom";

const GurdwaraService = ({ formData1, setFormData1 }) => {
  const [serviceData, setServiceData] = useState([
    {
      servicetype: "",
      serviceprice: "",
      serviceCount: "1",
      timeSlots: [{ days: [], from: "", to: "" }],
      serviceduration: "1",
    },
  ]);
  const location = useLocation();
  const serviceState = location.state;
  console.log(serviceState.serviceInfo, "serviceState");
  useEffect(() => {
    if (serviceState.serviceInfo) {
      const serviceParsedData = JSON.parse(serviceState.serviceInfo);
      console.log(serviceParsedData, "serviceParsedData");
      setServiceData(serviceParsedData);
    }
  }, []);
  const [menuIsOpen, setMenuIsOpen] = useState([]);
  const weekdays = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const updateServiceInfo = (
    newServicetype,
    newServiceprice,
    newServiceCount
  ) => {
    const serviceInfo = JSON.stringify({
      servicetype: newServicetype,
      serviceprice: newServiceprice,
      serviceCount: newServiceCount,
      timeSlots: serviceData.timeSlots.map((slot) => ({
        days: slot.days,
        from: slot.from,
        to: slot.to,
      })),
    });

    setFormData1((prevData) => ({
      ...prevData,
      serviceInfo,
    }));
  };

  const handleServiceChange = (value, index, field) => {
    const updatedRows = [...serviceData];
    updatedRows[index] = {
      ...updatedRows[index],
      [field]: value,
    };
    setServiceData(updatedRows);
    const serviceInfo = JSON.stringify(updatedRows);
    setFormData1((prevData) => ({
      ...prevData,
      serviceInfo,
    }));
  };

  const handleAddSlot = () => {
    const lastService = serviceData[serviceData.length - 1];
    if (
      !lastService.servicetype ||
      !lastService.serviceprice ||
      !lastService.timeSlots[0].days.length ||
      !lastService.timeSlots[0].from ||
      !lastService.timeSlots[0].to
    ) {
      toast.error("Please fill all details");
      return; // Prevent adding a new row
    }
    // Add the new time slot
    setServiceData((prev) => [
      ...prev,
      {
        servicetype: "",
        serviceprice: "",
        serviceCount: "1",
        timeSlots: [{ days: [], from: "", to: "" }],
        serviceduration: "1",
      },
    ]);
    setMenuIsOpen((prev) => [...prev, false]); // Add false for the new row
  };

  const handleTimeSlotChange = (serviceIndex, index, field, value) => {
    const newServiceData = [...serviceData];
    const newTimeSlots = [...newServiceData[serviceIndex].timeSlots];
    if (!newTimeSlots[index]) {
      newTimeSlots[index] = { days: [], from: "", to: "" }; // Default new time slot
    }
    if (field === "days") {
      newTimeSlots[index].days = value; // For days, value is an array
    } else {
      newTimeSlots[index][field] = value; // For 'from' and 'to'
    }
    if (newTimeSlots[index].from && newTimeSlots[index].to) {
      if (newTimeSlots[index].from >= newTimeSlots[index].to) {
        toast.error("The 'From' time must be earlier than the 'To' time.");
        return; // Prevent updating if validation fails
      }
    }
    newServiceData[serviceIndex].timeSlots = newTimeSlots;
    setServiceData(newServiceData);
    const serviceInfo = JSON.stringify(newServiceData);
    setFormData1((prevData) => ({
      ...prevData,
      serviceInfo,
    }));
  };

  const handleFocus = (index) => {
    const updatedMenuIsOpen = [...menuIsOpen];
    updatedMenuIsOpen[index] = true; // Open the specific select
    setMenuIsOpen(updatedMenuIsOpen);
  };

  const handleBlur = (index) => {
    const updatedMenuIsOpen = [...menuIsOpen];
    updatedMenuIsOpen[index] = false; // Close the specific select
    setMenuIsOpen(updatedMenuIsOpen);
  };

  const resetFormData = () => {
    setServiceData({
      servicetype: "",
      serviceprice: "",
      serviceCount: "1",
      timeSlots: [{ days: [], from: "", to: "" }],
      serviceduration: "1",
    });
  };

  const handleClear = (index) => {
    if (serviceData.length === 1) {
      setServiceData([
        {
          servicetype: "",
          serviceprice: "",
          serviceCount: "1",
          timeSlots: [{ days: [], from: "", to: "" }],
          serviceduration: "1",
        },
      ]);
    } else {
      const updatedRows = [...serviceData];
      const rowHasAddIcon = index === serviceData.length - 1;
      if (rowHasAddIcon) {
        updatedRows[index] = {
          servicetype: "",
          serviceprice: "",
          serviceCount: "1",
          timeSlots: [{ days: [], from: "", to: "" }],
          serviceduration: "1",
        };
      } else {
        updatedRows.splice(index, 1);
      }
      setServiceData(updatedRows);
      const serviceInfo = JSON.stringify(updatedRows);
      setFormData1((prevData) => ({
        ...prevData,
        serviceInfo,
      }));
      console.log(updatedRows, "updatedRows");
      setMenuIsOpen(updatedRows.map(() => false)); // Reset all menu states
    }
  };

  return (
    <div className="">
      <Label className="my-2 fs-5">Service Information:</Label>
      {serviceData.map((service, serviceindex) => (
        <div key={serviceindex}>
          <FormGroup row>
            <Row>
              <Col>
                <Label for="servicetype">Service Type</Label>
                <Input
                  type="select"
                  name="servicetype"
                  id="servicetype"
                  value={service.servicetype}
                  onChange={(e) =>
                    handleServiceChange(
                      e.target.value,
                      serviceindex,
                      "servicetype"
                    )
                  }
                >
                  <option value="0">Select Service Type</option>
                  <option value="1">Anand Karaj (Sikh Wedding)</option>
                  <option value="2">
                    Akhand Path (48 hour recitation of the Guru Granth Sahib
                    often booked for celebrations, life milestones, or in
                    remembrance of a loved one)
                  </option>
                  <option value="3">
                    Kirtan & Bhog ceremony (devotional singing & prayer for
                    religious events, birthdays, anniversaries, memorials)
                  </option>
                  <option value="4">
                    Ardaas (prayer for occasions such as housewarming, birth of
                    child, new business)
                  </option>
                  <option value="5">
                    Sukhmani Sahib Path (prayer for seeking peace & healing)
                  </option>
                  <option value="6">Langar Hall Rental</option>
                  <option value="7">Paath Services (specific readings)</option>
                  <option value="8">Amrit Sanchar</option>
                  <option value="9">Antim Ardasm (funeral services)</option>
                  <option value="10">Naam Karan (naming ceremony)</option>
                </Input>
              </Col>
            </Row>
          </FormGroup>
          <FormGroup row>
            <Row>
              <Col sm={4}>
                <Label for="serviceprice">Service Price</Label>
                <InputGroup>
                  <InputGroupText>$</InputGroupText>
                  <Input
                    type="text"
                    name="serviceprice"
                    id="serviceprice"
                    value={service.serviceprice}
                    onChange={(e) =>
                      handleServiceChange(
                        e.target.value,
                        serviceindex,
                        "serviceprice"
                      )
                    }
                  />
                </InputGroup>
              </Col>
              <Col sm={5}>
                <Label for="serviceCount" className="text-nowrap">
                  How many can do simultaneously every service
                </Label>
                <Input
                  type="select"
                  name="serviceCount"
                  id="serviceCount"
                  value={service.serviceCount}
                  onChange={(e) =>
                    handleServiceChange(
                      e.target.value,
                      serviceindex,
                      "serviceCount"
                    )
                  }
                >
                  {[...Array(10)].map((_, i) => (
                    <option key={i + 1} value={i + 1}>
                      {i + 1}
                    </option>
                  ))}
                </Input>
              </Col>
              <Col sm={3}>
                <Label>Duration</Label>
                <Input
                  type="select"
                  name="serviceduration"
                  id="serviceduration"
                  value={service.serviceduration}
                  onChange={(e) =>
                    handleServiceChange(
                      e.target.value,
                      serviceindex,
                      "serviceduration"
                    )
                  }
                >
                  {[...Array(10)].map((_, i) => (
                    <option key={i + 1} value={i + 1}>
                      {i + 1} {i + 1 === 1 ? "hr" : "hrs"}
                    </option>
                  ))}
                </Input>
              </Col>
            </Row>
          </FormGroup>
          <FormGroup row>
            {service.timeSlots.map((slot, index) => (
              <Row className="mt-2 mb-4 mx-0" key={index}>
                <Col sm={4} style={{ paddingLeft: 0 }}>
                  <Label>
                    Days <b className="text-danger">*</b>
                  </Label>
                  <Select
                    isMulti={true}
                    options={weekdays.map((day) => ({
                      value: day,
                      label: day,
                    }))}
                    value={slot.days.map((day) => ({
                      value: day,
                      label: day,
                    }))}
                    onChange={(selected) =>
                      handleTimeSlotChange(
                        serviceindex,
                        index,
                        "days",
                        selected.map((d) => d.value)
                      )
                    }
                    menuIsOpen={menuIsOpen[serviceindex] ?? false} // Use array to track state
                    onFocus={() => handleFocus(serviceindex)} // Open the specific select
                    onBlur={() => handleBlur(serviceindex)} // Close the specific select
                  />
                </Col>
                <Col sm={4}>
                  <Label>
                    From <b className="text-danger">*</b>
                  </Label>
                  <Input
                    type="time"
                    value={slot.from}
                    onChange={(e) =>
                      handleTimeSlotChange(
                        serviceindex,
                        index,
                        "from",
                        e.target.value
                      )
                    }
                    style={{ width: "100%" }}
                  />
                </Col>
                <Col sm={4}>
                  <Label>
                    To <b className="text-danger">*</b>
                  </Label>
                  <Input
                    type="time"
                    value={slot.to}
                    onChange={(e) =>
                      handleTimeSlotChange(
                        serviceindex,
                        index,
                        "to",
                        e.target.value
                      )
                    }
                    style={{ width: "100%" }}
                  />
                </Col>
              </Row>
            ))}
          </FormGroup>
          <FormGroup row>
            <div className="text-end">
              <Button
                color="secondary"
                onClick={() => handleClear(serviceindex)}
                className="me-2"
              >
                Clear
              </Button>
              {serviceindex === serviceData.length - 1 && (
                <Button color="primary" onClick={handleAddSlot}>
                  Add Service
                </Button>
              )}
            </div>
          </FormGroup>
        </div>
      ))}
    </div>
  );
};

export default GurdwaraService;
