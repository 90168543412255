import React from "react";
import { useSelector } from "react-redux";
import { FadeLoader } from "react-spinners";
import { Button } from "reactstrap";
import { createGurudwaraBooking } from "../../../components/Header/Data";
import { toast } from "react-toastify";
import moment from "moment-timezone";
import "moment-timezone/builds/moment-timezone-with-data";
const BookingList = ({ isLoading, bookingDetails, fetchBookingDetails }) => {
  const userinfo = useSelector((state) => state.user.userInfo);
  const islogin = useSelector((state) => state.user.isLogin);
  const user = islogin ? JSON.parse(userinfo) : null;
  const isAdmin = user && user.Role === "admin"; // Check if user is admin
  function convertTo12HourFormat(time24) {
    if (!time24) {
      return "";
    }
    const [hour24, minute] = time24.split(":").map(Number);
    if (isNaN(hour24) || isNaN(minute)) {
      return "";
    }
    const period = hour24 >= 12 ? "PM" : "AM";
    const hour12 = (hour24 % 12 || 12).toString().padStart(2, "0"); // Added padStart
    return `${hour12}:${minute.toString().padStart(2, "0")} ${period}`;
  }

  function formatDate(inputDate) {
    let date = new Date(inputDate);
    let monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    let year = date.getFullYear();
    let month = monthNames[date.getMonth()];
    let day = date.getDate();
    let formattedDay = day < 10 ? "0" + day : day;
    let formattedDate = `${month} ${formattedDay}, ${year}`;
    return formattedDate;
  }
  const handleApprove = async (service) => {
    let ID = service?.Id;
    console.log(service, "Approve");
    let formData = {
      Id: service.Id,
      bookingStatus: "5",
      updatedBy: user?.UserID,
      gurudwaraId: service?.gurudwaraId,
    };
    console.log(formData, ID, "ApprovedData");
    await createGurudwaraBooking(formData, ID);
    toast.success("Booking Approved Successfully");
    fetchBookingDetails();
  };
  const handleReject = async (service) => {
    let ID = service?.Id;
    console.log(service, "Reject");
    let formData = {
      Id: service.Id,
      bookingStatus: "6",
      updatedBy: user?.UserID,
      gurudwaraId: service?.gurudwaraId,
    };
    console.log(formData, ID, "RejectedData");
    await createGurudwaraBooking(formData, ID);
    toast.error("Booking Rejected");
    fetchBookingDetails();
  };

  console.log(bookingDetails, "bookingDetails");
  function convertToUserTimezone(utcTime, serviceTimezone) {
    console.log(utcTime, "utcTime");
    const timezone = serviceTimezone
      ? serviceTimezone?.split(",")[0]
      : "America/Los_Angeles";
    const localTime = moment
      .utc(utcTime)
      .tz(timezone)
      .format("YYYY-MM-DD HH:mm:ss");
    return localTime;
  }
  // const result = convertToUserTimezone("2024-11-25T11:54:28.000Z");
  // console.log(result, "result");
  return (
    <div className="inter-font">
      {isLoading ? (
        <FadeLoader // FadeLoader component
          css={{ margin: "0 auto" }}
          color={"#36D7B7"}
          loading={isLoading}
          className="position-absolute top-50 start-50 translate-middle"
        />
      ) : (
        <div>
          {bookingDetails.length > 0 ? (
            <div
              className="service-container mt-4 pb-3 "
              style={{ marginLeft: "0" }}
            >
              {bookingDetails.map((service, index) => (
                <div
                  id={index}
                  className="service rounded px-0 mb-0 mt-3 "
                  style={{ width: "97%" }}
                >
                  <div className="d-flex justify-content-between align-items-center mx-5 mb-1 mt-3">
                    <div>
                      <h5 className="text-decoration-none">
                        Booking ID# &nbsp;
                        <span className="text-success">{service?.Id}</span>
                      </h5>
                      {isAdmin && (
                        <h5 className="text-decoration-none">
                          Gurdwara Name# &nbsp;
                          <span className="text-success">
                            {service?.gurudwaraId === 1
                              ? "San Jose Gurdwara"
                              : service?.gurudwaraId === 2
                              ? "Fremont Gurdwara" // Replace with actual name
                              : service?.gurudwaraId === 3
                              ? "El Sobrante Gurdwara" // Replace with actual name
                              : service?.gurudwaraId === 4
                              ? "Fairfield Gurdwara" // Replace with actual name
                              : service?.gurudwaraId === 5
                              ? "Livermore Gurdwara" // Replace with actual name
                              : "Unknown Gurdwara"}
                          </span>
                        </h5>
                      )}
                      <h6>
                        Payment Status#{" "}
                        {service?.paymentStatus === "COMPLETED" ? (
                          <span className="text-success fw-bolder">
                            Completed
                          </span>
                        ) : (
                          <span className="text-danger fw-bolder">
                            Not Completed
                          </span>
                        )}
                      </h6>
                    </div>
                    <div>
                      {!isAdmin &&
                      service?.paymentStatus === "COMPLETED" &&
                      service?.bookingStatus !== "5" &&
                      service?.bookingStatus !== "6" ? (
                        <>
                          <Button
                            color="success"
                            className="mx-1"
                            onClick={() => handleApprove(service)}
                          >
                            Approve
                          </Button>
                          <Button
                            color="danger"
                            className="mx-1"
                            onClick={() => handleReject(service)}
                          >
                            Reject
                          </Button>
                        </>
                      ) : (
                        <>
                          {/* Show buttons only if payment is completed and order is not approved */}
                          <h6>
                            Order Status#{" "}
                            {service?.bookingStatus === "5" ? (
                              <span className="text-success fw-bolder">
                                Approved
                              </span>
                            ) : service?.bookingStatus === "6" ? (
                              <span className="text-danger fw-bolder">
                                Rejected
                              </span>
                            ) : (
                              <span className="fw-bolder">Pending</span>
                            )}
                          </h6>

                          <h6>
                            Form Submitted On:&nbsp;
                            {formatDate(
                              convertToUserTimezone(
                                service?.updatedAt,
                                service?.timeZone
                              ).split(" ")[0]
                            )}
                            &nbsp;
                            {convertTo12HourFormat(
                              convertToUserTimezone(
                                service?.updatedAt,
                                service?.timeZone
                              ).split(" ")[1]
                            )}
                          </h6>
                        </>
                      )}
                      {/* <h6>
                        Payment Status#{" "}
                        {service?.paymentStatus === "COMPLETED" ? (
                          <span className="text-success fw-bolder">
                            Completed
                          </span>
                        ) : (
                          <span className="text-danger fw-bolder">
                            Not Completed
                          </span>
                        )}
                      </h6> */}
                    </div>
                    {/* {service?.paymentStatus ? (
                      <h5 className=" text-decoration-none">
                        Order Status#{" "}
                        <span className="text-success">
                          {service?.paymentStatus}
                        </span>
                      </h5>
                    ) : (
                      <h5>Booking Status# {service?.bookingStatus}</h5>
                    )} */}
                  </div>
                  <div className="row mx-5 mb-1 mt-2">
                    <div className="col-6 my-1">
                      <div className="my-2">
                        Name:{" "}
                        <span className="fs-6 fw-bold">{service?.Name}</span>
                      </div>
                      <div className="my-2">
                        Email:{" "}
                        <span className="fs-6 fw-bold">{service?.Email}</span>
                      </div>
                      <div className="my-2">
                        Phone Number:{" "}
                        <span className="fs-6 fw-bold">
                          {service?.mobileOne}
                        </span>
                      </div>
                    </div>
                    <div className="col-6 my-1">
                      {service?.eventDate !== null && (
                        <div className="my-2">
                          Event Date&Time:{" "}
                          <span className="fs-6 fw-bold">
                            {moment(service?.eventDate).format("MMMM D, YYYY")}
                            &nbsp;
                            {moment(service?.eventTime, "HH:mm").format(
                              "hh:mm A"
                            )}
                          </span>
                        </div>
                      )}
                      {service?.serviceType !== null && (
                        <div className="my-2">
                          Event Type:{" "}
                          <span className="fs-6 fw-bold">
                            {service.serviceType === "1"
                              ? "Anand Karaj (Sikh Wedding)"
                              : service.serviceType === "2"
                              ? "Akhand Path (48 hour recitation of the Guru Granth Sahib)"
                              : service.serviceType === " 3"
                              ? "Kirtan & Bhog ceremony (devotional singing & prayer for religious events, birthdays, anniversaries, memorials)"
                              : service.serviceType === "4"
                              ? "Ardaas (prayer for occasions such as housewarming, birth of child, new business)"
                              : service.serviceType === "5"
                              ? "Sukhmani Sahib Path (prayer for seeking peace & healing)"
                              : service.serviceType === "6"
                              ? "Langar Hall Rental"
                              : service.serviceType === "7"
                              ? "Paath Services (specific readings)"
                              : service.serviceType === "8"
                              ? "Amrit Sanchar"
                              : service.serviceType === "9"
                              ? "Antim Ardasm (funeral services)"
                              : service.serviceType === "10"
                              ? "Naam Karan (naming ceremony)"
                              : service.serviceType}
                          </span>
                        </div>
                      )}
                      {service?.Amount !== 0 && (
                        <div className="my-2">
                          Order Amount:{" "}
                          <span className="fs-6 fw-bold">
                            {service?.Amount}
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="service-container" style={{ height: "150px" }}>
              No Bookings Found
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default BookingList;
