// ThankYouModal.js
import React from "react";
import { Button } from "reactstrap";
import Modal from "react-modal";
import ThankYou from "../../../assets/images/thankyou.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import "./Order.css"; // Custom CSS file for styling
import { useNavigate } from "react-router-dom";

const ThankYouModal = ({ show, onHide, totalPrice, state }) => {
  const navigate = useNavigate();
  const onClose = () => {
    onHide();
    state?.hire
      ? navigate("/dashboardpage/orders")
      : state?.item
      ? navigate(`/dashboardpage/my-items?tab=Purchased`)
      : navigate("/dashboardpage/inbox/new-inbox");
    window.scroll(0, 0);
  };
  console.log(state?.hire, "state.hire");
  return (
    <Modal isOpen={show} onRequestClose={onHide} className="thank-you-modal">
      <div className="modal-content">
        <div className="thank-icon-container">
          <FontAwesomeIcon icon={faCheckCircle} className="icon" />
        </div>
        <img src={ThankYou} className="image" />
        {totalPrice !== 0 ? (
          <p className="message">
            Your booking is Finalized once the vendor approves your transaction.
            Vendor has the right to decline the order request. You will only be
            charged upon order Approval.
          </p>
        ) : (
          <p>
            The customer phone number has been made available within the inbox
            section.{" "}
          </p>
        )}
        <Button color="primary" onClick={onClose} className="close-button">
          Close
        </Button>
      </div>
    </Modal>
  );
};

export default ThankYouModal;
