import React, { useEffect, useState } from "react";
import { Button, Col, FormGroup, Input, Label, Row } from "reactstrap";
import Payment from "../../settingsComponents/Payment";
import { useDispatch, useSelector } from "react-redux";
import {
  setCurrentStep,
  setPaymentStatus,
  setResponseId,
  setSelectedServiceType,
  setTermsConditions,
} from "../../../../redux/slices/GurdwaraSlice";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { createGurudwaraBooking } from "../../../../components/Header/Data";
import { FaCheckCircle } from "react-icons/fa";
import { green } from "@mui/material/colors";

const BookGurdwaraStep4 = () => {
  const {
    selectedServicePrice,
    selectedDate,
    selectedTime,
    fullName,
    email,
    serviceType,
    mobileNumber1,
    mobileNumber2,
    mobileNumber3,
    eventDate,
    eventTime,
    eventLocation,
    additionalPeople,
    selectedServiceType,
    termsConditions,
    responseId,
    currentStep,
    paymentStatus,
    paypalID,
    terms,
  } = useSelector((state) => state.gurdwara);
  console.log(selectedServicePrice, "selectedServicePrice");
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const id = parseInt(queryParams.get("id"), 10); // Keep
  const islogin = useSelector((state) => state.user.isLogin);
  const userinfo = useSelector((state) => state.user.userInfo);
  const user = islogin ? JSON.parse(userinfo) : null;
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  let gurdwaraBooking = true;
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (paymentStatus === "COMPLETED" && !isFormSubmitted) {
        const message = "Please Submit the form to confirm Booking";
        event.returnValue = message; // For most browsers
        return message; // For Firefox
      }
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [paymentStatus, isFormSubmitted]);

  useEffect(() => {
    // Reset these states if any of the critical fields change
    dispatch(setPaymentStatus(""));
    dispatch(setTermsConditions(0));
  }, [selectedServiceType, selectedDate, selectedTime, responseId, dispatch]);
  console.log(termsConditions, "termsConditions");
  console.log(user?.UserID, "UserID");
  console.log(paymentStatus, "paymentStatus");
  return (
    // <div>
    //   <div className="d-flex align-items-center justify-content-center">
    //     <div
    //       className="page1"
    //       style={{ height: currentStep === 4 ? "750px" : "405px" }}
    //     >
    //       <div className="d-flex justify-content-between mx-5 my-4">
    //         <div className="fw-bold fs-5">
    //           <span
    //             onClick={() => setCurrentStep((prev) => prev - 3)}
    //             className="text-primary"
    //             style={{ cursor: "pointer" }}
    //           >
    //             Section1
    //           </span>
    //           &gt;
    //           <span
    //             onClick={() => setCurrentStep((prev) => prev - 2)}
    //             className="text-primary"
    //             style={{ cursor: "pointer" }}
    //           >
    //             Section2
    //           </span>
    //           &gt;
    //           <span
    //             onClick={() => setCurrentStep((prev) => prev - 1)}
    //             className="text-primary"
    //             style={{ cursor: "pointer" }}
    //           >
    //             Section3
    //           </span>
    //           &gt;
    //           <span className="text-decoration-underline">Section4</span>
    //         </div>
    //       </div>
    <div className="mx-5 my-4">
      <Col className="booking-form p-4 mt-3">
        <h5 className="pb-3">
          Terms <b className="text-danger">*</b>
        </h5>
        <h6 className="fst-italic pb-3 small">
          <p className="text-justify pb-1">{terms}</p>
        </h6>
        <div class="mb-3 form-check gurdwara_checkbox">
          <FormGroup>
            <Input
              type="checkbox"
              name="Terms"
              checked={termsConditions === 1}
              onChange={(e) => {
                dispatch(setTermsConditions(e.target.checked ? 1 : 0));
              }}
              class="form-check-input"
              style={{ color: "green" }}
              id="exampleCheck1"
              disabled={paymentStatus === "COMPLETED"}
            />
            <Label
              class="form-check-label ps-2 text-success"
              style={{ color: "green" }}
              for="exampleCheck1"
              required
            >
              I Understand And Accept Terms
            </Label>
          </FormGroup>
        </div>
      </Col>
      {paymentStatus === "COMPLETED" ? (
        <div className="d-flex flex-column align-items-center justify-content-center p-4 mt-4 mb-5">
          <span className="text-success mb-4">
            <FaCheckCircle size={70} />
          </span>
          <h4 className="fw-bolder">PAYMENT COMPLETED SUCCESSFULLY!</h4>
          <h5 className="text-success mt-2">
            Booking Request Sent to the Gurdwara for Approval
          </h5>
          <h4 className="fw-bolder mt-4">Order number: {responseId}</h4>
        </div>
      ) : (
        <div className="d-flex flex-column align-items-center justify-content-center mt-4 mb-5">
          <h1 className="my-3 text-success">
            Total Due $ {selectedServicePrice}
          </h1>
          <div className="w-50 paypal" style={{ marginTop: "-3%" }}>
            <Payment
              totalPrice={selectedServicePrice}
              order={""}
              state={{ bookingGurdwara: true }}
            />
          </div>
        </div>
      )}
      {/* <div
        className="d-flex justify-content-between align-items-center"
        style={{ marginTop: "45px" }}
      >
        <h5 className="mt-2 fw-bolder">Powered by My Sikh Community</h5>
        <Button color="danger" onClick={handleSubmit}>
          Submit
        </Button>
      </div> */}
    </div>
    //     </div>
    //   </div>
    // </div>
  );
};

export default BookGurdwaraStep4;
