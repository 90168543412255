import React, { useEffect, useState, useMemo } from "react";
import { Col, Container, Row } from "reactstrap";
import ProductSection from "../../UI/ProductSection";
import "../../../styles/allproduct.css";
import {
  fetchCategories,
  fetchLocalCommunityAds,
  fetchSubCategories,
} from "../Data";
import { useLocation } from "react-router-dom";
import { FadeLoader } from "react-spinners";
import "./Products.css";
const Dashboard = () => {
  const location = useLocation();
  const [productlist, setProductList] = useState([]);
  const [lastRefreshed, setLastRefreshed] = useState(new Date());
  const [elapsedSeconds, setElapsedSeconds] = useState(0);
  const [loading, setLoading] = useState(false);
  const [subCategories, setSubCategories] = useState([]);
  let pageNumber = 1;
  const calculateLastRefreshed = () => {
    const currentTime = new Date();
    const diff = currentTime - new Date(lastRefreshed) + elapsedSeconds * 1000; // Add elapsed seconds to the difference
    const seconds = Math.floor(diff / 1000) % 60; // Calculate seconds
    const minutes = Math.floor(diff / (1000 * 60)) % 60; // Calculate minutes
    const hours = Math.floor(diff / (1000 * 60 * 60)); // Calculate hours
    const days = Math.floor(hours / 24); // Calculate days
    let result = "Last refreshed: ";
    result += lastRefreshed.toLocaleDateString() + " ";
    result += " (";
    result += days > 0 ? `${days} days ` : "";
    result += hours > 0 ? `${hours} hours ` : "";
    result += minutes > 0 ? `${minutes} minutes ` : "";
    result += `${seconds} seconds ago)`;
    return result;
  };
  const fetchData = async () => {
    setLoading(true);
    const category = await fetchCategories();
    const subCategories = await fetchSubCategories();
    const filterCategoryByName = category.filter(
      (cat) => cat.Name === "Local Community Ads"
    );
    const filterSubCat = subCategories.filter(
      (subCat) => subCat.CategoryId === filterCategoryByName[0].Id
    );
    setSubCategories(filterSubCat);
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
    const intervalId = setInterval(() => {
      setLastRefreshed(new Date());
      setElapsedSeconds((prevSeconds) => prevSeconds + 1); // Increment elapsed seconds
    }, 1000);

    return () => clearInterval(intervalId); // Cleanup interval on component unmount
  }, []);
  const fetchLCA = async (payload) => {
    setLoading(true);
    if (location.state && location.state.gurudwara) {
      payload = {
        ...payload,
        Gurudwara: location.state.gurudwara,
      };
    }
    const ads = await fetchLocalCommunityAds(payload); // pass the payload to the API request
    setProductList((prevProducts) => {
      const existingIds = prevProducts.map((product) => product.Id);
      const newProducts = ads.filter((ad) => !existingIds.includes(ad.Id));
      return [...prevProducts, ...newProducts];
    });
    setLoading(false);
    if (ads.length === 50) {
      pageNumber += 1;
      const payload = { pageId: pageNumber };
      fetchLCA(payload);
    }
  };

  useEffect(() => {
    const payload = { pageId: pageNumber };
    if (location.state && location.state.gurudwara) {
      payload.Gurudwara = location.state.gurudwara;
    }
    fetchLCA(payload);
    const intervalId = setInterval(() => {
      fetchLCA(payload);
    }, 3600000); // Call fetchLCA every 60 minutes (60 minutes * 60 seconds * 1000 milliseconds)

    return () => clearInterval(intervalId); // Cleanup interval on component unmount
  }, [location.state]);

  const filterGurdwara = useMemo(
    () => productlist.filter((item) => item.engagedCouple !== 1),
    [productlist]
  );

  const memoizedProductSection = useMemo(
    () => (
      <ProductSection data={filterGurdwara} subCategories={subCategories} />
    ),
    [filterGurdwara]
  );

  return (
    <>
      <section className="bg-white  inter-font">
        <Container fluid className="px-4">
          <Row className="px-2">
            <Col
              xl={4}
              lg={4}
              md={4}
              sm={12}
              xs={12}
              className="text-start mt-2"
            >
              <h5>Latest Local Community ADs - </h5>
            </Col>
            <Col
              xl={4}
              lg={4}
              md={4}
              sm={12}
              xs={12}
              className="flashing-text mt-2"
            >
              <span>
                <b>CALL (800)245-3610 & Dial Listing number</b>
              </span>
            </Col>
            <Col xl={4} lg={4} md={4} sm={12} xs={12} className="text-end mt-2">
              <small>{calculateLastRefreshed()}</small>
            </Col>
          </Row>
          <Row className="all_product_Card">
            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
              {!loading ? (
                <>
                  {productlist.length > 0 ? (
                    <>{memoizedProductSection}</>
                  ) : (
                    <h5 className="text-center m-5">No ADs</h5>
                  )}
                </>
              ) : (
                <FadeLoader
                  color={"#36D7B7"}
                  loading={true}
                  className="top-50 start-50 translate-middle"
                />
              )}
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Dashboard;
