import React, { useEffect, useState } from "react";
import { Input, Button, Form } from "reactstrap";
import { fetchCategories, fetchSubCategories, fetchItems } from "../Data";
import { useDispatch, useSelector } from "react-redux";
import { setSearchQuery } from "../../../redux/slices/LcaSlice";

export const SearchLca = ({ initialSearchQuery }) => {
  const dispatch = useDispatch();
  const { allProducts, Lca, finalProducts, bwv, finalBwv, searchQuery } =
    useSelector((state) => state.lcAds);
  const [placeSelected, setPlaceSelected] = useState(false); // New state variable
  const [activeSuggestion, setActiveSuggestion] = useState(0); // New state variable
  const [cat, setCat] = useState([]);
  const [subCat, setSubCat] = useState([]);
  const [item, setItem] = useState([]);
  const [showNoResults, setShowNoResults] = useState(false);
  const [showNoCards, setShowNoCards] = useState(false);
  const onSearch = () => {
    setActiveSuggestion(null);
  };
  useEffect(() => {
    const fetchCat = async () => {
      if (!searchQuery) {
        const categoriesData = await fetchCategories();
        const subCategoriesData = await fetchSubCategories();
        const itemsData = await fetchItems();
        setCat(categoriesData);
        setSubCat(subCategoriesData);
        setItem(itemsData);
      }
    };
    fetchCat();
  }, [searchQuery]);

  useEffect(() => {
    const handleDocumentClick = (event) => {
      if (activeSuggestion >= 0) {
        //setSearchQuery("");
        setActiveSuggestion(null);
        setPlaceSelected(true); // Close the active suggestion
      }
    };
    document.addEventListener("click", handleDocumentClick);
    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, [activeSuggestion]);
  const filteredData = [
    ...cat,
    ...subCat,
    ...item,
    ...(finalProducts || []),
    ...(finalBwv || []),
  ].filter((data) => {
    return (
      !searchQuery ||
      (data.Name &&
        data.Name.toLowerCase().includes(searchQuery.toLowerCase())) ||
      (data.catName &&
        data.catName.toLowerCase().includes(searchQuery.toLowerCase())) ||
      (data.Title &&
        data.Title.toLowerCase().includes(searchQuery.toLowerCase())) ||
      (data.listName &&
        data.listName.toLowerCase().includes(searchQuery.toLowerCase())) ||
      (data.Location &&
        data.Location.toLowerCase().includes(searchQuery.toLowerCase())) ||
      (data.Description &&
        data.Description.toLowerCase().includes(searchQuery.toLowerCase())) ||
      (data.FullName &&
        data.FullName.toLowerCase().includes(searchQuery.toLowerCase()))
    );
  });
 
  const handleSelect = (data, index, type) => {
    console.log("name in sq", data);
    let name;
    if (
      searchQuery &&
      data.FullName &&
      data.FullName.toLowerCase().includes(searchQuery.toLowerCase())
    ) {
      name = data.FullName;
    } else if (
      searchQuery &&
      data.Description &&
      data.Description.toLowerCase().includes(searchQuery.toLowerCase())
    ) {
      name = data.Description;
    } else if (
      searchQuery &&
      data.Title &&
      data.Title.toLowerCase().includes(searchQuery.toLowerCase())
    ) {
      name = data.Title;
    } else if (
      searchQuery &&
      data.Name &&
      data.Name.toLowerCase().includes(searchQuery.toLowerCase())
    ) {
      name = data.Name;
    } else if (
      searchQuery &&
      data.catName &&
      data.catName.toLowerCase().includes(searchQuery.toLowerCase())
    ) {
      name = data.catName;
    } else if (
      searchQuery &&
      data.listName &&
      data.listName.toLowerCase().includes(searchQuery.toLowerCase())
    ) {
      name = data.listName;
    } else if (
      searchQuery &&
      data.Location &&
      data.Location.toLowerCase().includes(searchQuery.toLowerCase())
    ) {
      name = data.Location;
    } else {
      name =
        data.FullName ||
        data.Description ||
        data.Title ||
        data.Name ||
        data.catName ||
        data.listName ||
        data.Location;
    }
    dispatch(setSearchQuery(name));
    setActiveSuggestion(index);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      if (searchQuery && activeSuggestion >= 0) {
        const selectedItem = filteredData[activeSuggestion];
        handleSelect(selectedItem, activeSuggestion);
        setCat([]);
        setSubCat([]);
        setItem([]);
        setActiveSuggestion(null);
        setPlaceSelected(true);
        if (
          !filteredData.some(
            (data) =>
              data &&
              data.Name &&
              data.Name.toLowerCase() === searchQuery.toLowerCase()
          )
        ) {
          setShowNoCards(true);
        } else {
          setShowNoCards(false);
        }
      }
    } else if (e.key === "ArrowDown") {
      if (activeSuggestion < filteredData.length - 1) {
        setActiveSuggestion(activeSuggestion + 1);
      }
    } else if (e.key === "ArrowUp") {
      if (activeSuggestion > 0) {
        setActiveSuggestion(activeSuggestion - 1);
      }
    }
  };

  return (
    <>
      <div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Input
            type="search"
            className="m-2"
            style={{
              width: "69.3%",
            }}
            value={searchQuery}
            onChange={(e) => {
              dispatch(setSearchQuery(e.target.value));
              setPlaceSelected(false);
            }}
            placeholder="Search..."
            onKeyDown={handleKeyDown}
          />
          <Button className="bg-primary m-0" onClick={onSearch}>
            Search
          </Button>
          {/* <CustomButton variant="primary">Search</CustomButton> */}
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            position: "absolute",
            zIndex: 9,
            backgroundColor: "#FAF9F6",
            color: "white",
            left: "316px",
            width: "48%",
            borderRadius: "10px",
          }}
        >
          {searchQuery && !placeSelected && (
            <ul className="list m-0 p-0 list-group" style={{ width: "100%" }}>
              {filteredData.length > 0 ? (
                filteredData.map((data, index) => (
                  <li
                    key={data.id}
                    onClick={() => {
                      handleSelect(data, index);
                      setCat([]);
                      setSubCat([]);
                      setItem([]);
                      setActiveSuggestion(null);
                      setPlaceSelected(true);
                    }}
                    onMouseEnter={(e) => {
                      e.target.style.backgroundColor = "#5435C2";
                      e.target.style.color = "white";
                      e.target.style.borderRadius = "10px";
                    }}
                    onMouseLeave={(e) => {
                      e.target.style.backgroundColor = "white";
                      e.target.style.color = "black";
                    }}
                    style={
                      activeSuggestion === index
                        ? {
                            backgroundColor: "#5435C2",
                            color: "white",
                            borderRadius: "10px",
                          }
                        : {}
                    }
                    className="list-group-item text-decoration-none text-start w-100 d-flex justify-content-between align-items-center"
                  >
                    {searchQuery &&
                    data.FullName &&
                    data.FullName.toLowerCase().includes(
                      searchQuery.toLowerCase()
                    )
                      ? data.FullName
                      : searchQuery &&
                        data.Description &&
                        data.Description.toLowerCase().includes(
                          searchQuery.toLowerCase()
                        )
                      ? data.Description
                      : searchQuery &&
                        data.Title &&
                        data.Title.toLowerCase().includes(
                          searchQuery.toLowerCase()
                        )
                      ? data.Title
                      : searchQuery &&
                        data.Name &&
                        data.Name.toLowerCase().includes(
                          searchQuery.toLowerCase()
                        )
                      ? data.Name
                      : searchQuery &&
                        data.catName &&
                        data.catName
                          .toLowerCase()
                          .includes(searchQuery.toLowerCase())
                      ? data.catName
                      : searchQuery &&
                        data.listName &&
                        data.listName
                          .toLowerCase()
                          .includes(searchQuery.toLowerCase())
                      ? data.listName
                      : data.Title ||
                        data.FullName ||
                        data.Description ||
                        data.Name ||
                        data.catName ||
                        data.listName}
                  </li>
                ))
              ) : (
                <li className="list-group-item text-center fw-bold">
                  No search results
                </li>
              )}
            </ul>
          )}
        </div>
      </div>
    </>
  );
};
