import React from "react";
import { FaFileAlt, FaRegFileWord } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { setMessageText } from "../../../redux/slices/MsgSlice";

const ContactTemplates = ({ templates }) => {
  const { messageText } = useSelector((state) => state.messages);
  const dispatch = useDispatch();
  const handleTemplateClick = (template) => {
    dispatch(setMessageText(template.Description));
  };
  console.log(messageText, "messageText");
  return (
    <div>
      <div className="my-templates">
        {templates && templates.length > 0 ? (
          <>
            {templates.map((template, index) => (
              <div
                key={index}
                className="inbox-template-card"
                onClick={() => {
                  handleTemplateClick(template);
                }}
              >
                <div className="my-temp-card">
                  <strong>{template.Title}</strong>
                  <span>
                    {template.Description.length > 120
                      ? `${template.Description.substring(0, 120)}...`
                      : template.Description}
                  </span>
                </div>
              </div>
            ))}
          </>
        ) : (
          <div className="text-center">No Templates</div>
        )}
      </div>
    </div>
  );
};

export default ContactTemplates;
