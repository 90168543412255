import { Box, Button, Modal, Typography } from "@mui/material";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Modal.css";
import { useSelector } from "react-redux";
import { makeCall, sendMsgMail, virtualTourClick } from "../Data";
import { GetZoomLink } from "../Data2";
import { toast } from "react-toastify";

const StartZoomVendorModal = ({ open, handleClose }) => {
  const selectedCard = useSelector((state) => state.bwvData.selectedService);
  const islogin = useSelector((state) => state.user.isLogin);
  const userinfo = useSelector((state) => state.user.userInfo);
  const user = islogin ? JSON.parse(userinfo) : null;
  const [isZoomStarted, setIsZoomStarted] = useState(false);
  const [link, setLink] = useState(null);
  const [loading, setLoading] = useState(false);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: {
      xs: "90%", // 90% width on mobile
      sm: "70%", // 70% width on tablet
      md: "730px", // 600px width on desktop
    },
    height: {
      xs: "auto", // auto height on mobile
      sm: "auto", // auto height on tablet
      md: "730px", // 400px height on desktop
    },
    bgcolor: "background.paper",
    border: "1px solid #000",
    boxShadow: 24,
    p: 4,
    overflow: "hidden", // Prevent overflow on smaller screens
  };
  function formatDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");

    return `${year}-${month}-${day} ${hours}:${minutes}`;
  }
  const now = new Date();
  const formattedDate = formatDate(now);
  const handleStartZoomCall = async () => {
    setLoading(true);
    const serviceInfo = JSON.parse(selectedCard?.additionalPeople);
    const newPerson1 = {
      email: user?.Email,
      phoneNumber: user?.Phone,
      countryCode: user?.CountryCode,
    };
    let newPerson2;
    let newPerson3;
    if (selectedCard.profilePhone === selectedCard.Phone) {
      newPerson2 = {
        email: selectedCard?.Email, // Assuming anotherUser is defined
        phoneNumber: selectedCard?.profilePhone,
        countryCode: selectedCard?.countryCode,
      };
    } else {
      newPerson2 = {
        email: selectedCard?.Email, // Assuming anotherUser is defined
        phoneNumber: selectedCard?.profilePhone,
        countryCode: selectedCard?.countryCode,
      };
      newPerson3 = {
        email: selectedCard?.Email, // Assuming anotherUser is defined
        phoneNumber: selectedCard?.Phone,
        countryCode: selectedCard?.countryCode,
      };
    }
    serviceInfo.unshift(newPerson1);
    serviceInfo.push(newPerson2);
    if (newPerson3) {
      serviceInfo.push(newPerson3);
    }
    const stringifiedAdditionalPeople = JSON.stringify(serviceInfo);
    let formData = {
      email: user?.Email,
      phoneNumber: user?.Phone,
      countryCode: user?.CountryCode,
      phoneDetails: stringifiedAdditionalPeople,
    };
    console.log(formData, "formData");
    const response = await makeCall(formData);
    // toast.success("Calling venue now, please hold");
    if (response === "initiating call") {
      const formData = {
        startTime: formattedDate,
        needName: selectedCard?.listName,
      };
      const linkResponse = await GetZoomLink(formData, selectedCard?.Id);
      setLink(linkResponse.join_url);
      let formData1 = {
        zoomlink: linkResponse.join_url,
        phoneDetails: stringifiedAdditionalPeople,
        customerId: user?.UserID,
        ownerId: parseInt(selectedCard?.createdBy),
        Type: "details",
      };
      console.log(formData1, "formData1");
      await sendMsgMail(formData1);
      setLoading(false);
      setIsZoomStarted(true); // Set state to show the iframe
      await virtualTourClick(selectedCard?.Id);
    } else {
      throw new Error("Response from makeCall was invalid");
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        {!isZoomStarted ? (
          <>
            <Box>
              <Typography
                id="modal-title"
                variant="h5"
                fontWeight="bold"
                textAlign="center"
                mb={2}
                className="my-5"
              >
                Start Virtual Tour
              </Typography>
              <Typography
                id="modal-description"
                variant="body1"
                textAlign="center"
                mb={3}
                className="my-5"
              >
                The vendor will answer your zoom call request & give you a 15
                minute meeting. They’ll showcase their offering. <br />
                <br />
                You can keep your camera Off, if you wish.
              </Typography>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={handleStartZoomCall}
                sx={{ mb: 2 }}
                className="my-5"
                disabled={loading}
              >
                {loading ? (
                  <span
                    className="spinner-border spinner-border-sm text-dark"
                    role="status"
                    aria-hidden="true"
                  ></span>
                ) : (
                  "Start Tour Now"
                )}
              </Button>
              <div className="start-zoom my-5">
                <Typography variant="body2" className="start-zoom-text my-3">
                  "Please wait up to 1 minute for the vendor to join the
                  meeting. Try again later if vendor is busy & doesn't join
                  within the 1 minute wait”{" "}
                </Typography>
              </div>
              <Link
                href="#"
                onClick={handleClose}
                className="mt-5"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  fontSize: "x-small",
                  textDecoration: "underline",
                  cursor: "pointer",
                  margin: "10px",
                }}
              >
                Continue Browsing Site
              </Link>
            </Box>
          </>
        ) : (
          <iframe
            src={link}
            width="100%"
            height="100%"
            frameBorder="0"
            allow="camera; microphone; fullscreen; autoplay"
            title="Zoom Call"
            style={{ border: 0 }} // Remove default border
          />
        )}
      </Box>
    </Modal>
  );
};

export default StartZoomVendorModal;
