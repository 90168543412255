import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { Row, Col, Input, Label, Alert } from "reactstrap";
import { FaPlusCircle } from "react-icons/fa";
import { postOrder, sendRequestQuote } from "../Data";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
const RequestAppointment = ({
  isOpen,
  selectedDate,
  setSelectedDate,
  selectedHour,
  setSelectedHour,
  closeModal,
  quotePop,
}) => {
  const navigate = useNavigate();
  const islogin = useSelector((state) => state.user.isLogin);
  const userinfo = useSelector((state) => state.user.userInfo);
  const selectedCard = useSelector((state) => state.bwvData.selectedService);
  const availableDays = selectedCard?.availableDays;
  const user = islogin ? JSON.parse(userinfo) : null;
  const [images, setImages] = useState(Array(5).fill(null));
  const [form, setForm] = useState({
    deadline: "",
    message: "",
    images: [],
    budget: null,
    startTime: null,
    endTime: null,
    date: new Date(),
    gift: false,
  });
  const [error, setError] = useState("");
  function convertTo12HourFormat(time) {
    if (!time) {
      return "";
    }
    const [hour24, minute] = time.split(":").map(Number);
    if (isNaN(hour24) || isNaN(minute)) {
      return "";
    }
    const period = hour24 >= 12 ? "PM" : "AM";
    const hour12 = (hour24 % 12 || 12).toString().padStart(2, "0"); // Added padStart
    return `${hour12}:${minute.toString().padStart(2, "0")} ${period}`;
  }
  useEffect(() => {
    let time = `${selectedHour}:00`;
    const formattedHour = (parseInt(time) % 12 || 12)
      .toString()
      .padStart(2, "0");
    setForm((prevForm) => ({
      ...prevForm,
      startTime: convertTo12HourFormat(time),
    }));
  }, [selectedHour]);
  useEffect(() => {
    if (selectedDate) {
      setForm({
        ...form,
        date: new Date(
          selectedDate.getTime() - selectedDate.getTimezoneOffset() * 60000
        )
          .toISOString()
          .split("T")[0],
      });
    }
  }, [selectedDate]);

  const resetForm = () => {
    setForm({
      deadline: "",
      message: "",
      images: [],
      budget: "",
      startTime: "",
      endTime: "",
      date: new Date(),
      gift: false,
    });
    setImages(Array(5).fill(null));
  };
  const handleImageChange = (index, e) => {
    const files = e.target.files;
    if (files.length > 0) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const newImages = [...images];
        newImages[index] = e.target.result; // Base64 data URI
        setImages(newImages);
        setForm((prevInfo) => ({
          ...prevInfo,
          images: newImages.filter(Boolean), // Filter out null values
        }));
      };
      reader.readAsDataURL(files[0]);
    }
  };

  const handleRequest = async () => {
    if (quotePop) {
      const validAvailableDays = availableDays.filter((available) => {
        return available.days.length > 0 && available.from && available.to;
      });

      const isHourAvailable = validAvailableDays?.some((available) => {
        const days = available?.days;
        const startTime = parseInt(available.from.split(":")[0]);
        const endTime = parseInt(available.to.split(":")[0]);
        const selectedDateObj = moment(selectedDate);
        const selectedDayOfWeek = selectedDateObj.format("dddd");
        const startHour = parseInt(form.startTime.split(":")[0]);
        const endHour = parseInt(form.endTime.split(":")[0]);
        return (
          days.includes(selectedDayOfWeek) &&
          startHour >= startTime &&
          endHour <= endTime
        );
      });

      if (!isHourAvailable) {
        // toast.warning("Provider not available in this time");
        setError(
          "Please close this window & review the Vendor Availability Calendar to learn when vendor is available. Then click Request In person Appointment"
        );
        window.scrollTo(0, 0);
        return;
      }
    }
    if (!islogin) {
      navigate("/login");
      toast.info("Please Login");
      return;
    } else if (!form.date) {
      toast.error("Please select date");
      return;
    } else if (form.date < new Date().toISOString().split("T")[0]) {
      toast.error("Please select a future date");
      return;
    } else if (!form.startTime) {
      toast.error("Please select start time");
      return;
    } else if (!form.endTime) {
      toast.error("Please Select End time");
      return;
    } else if (
      new Date(`01/01/2024 ${form.endTime}`) <=
      new Date(`01/01/2024 ${form.startTime}`)
    ) {
      toast.error("End time cannot be before start time");
      return;
    } else if (!form.budget) {
      toast.error("Please enter budget");
      return;
    } else if (!form.message) {
      toast.error("Please enter message");
      return;
    }
    let formData = {
      customerId: selectedCard?.createdBy,
      postName: selectedCard?.listName,
      serviceId: selectedCard?.Id,
      reserveDate: form.date,
      startTime: form.startTime,
      endTime: form.endTime,
      Deadline: form.deadline,
      Service: form.service,
      optionType: selectedCard?.hourlyPrice ? 0 : 1, // hourly based 0 or flat fee 1
      Photo: form.images,
      Budget: form.budget ? form.budget : 0,
      serviceNeed: "service",
      createdBy: user.UserID,
      roleId: user.RoleId,
      Description: form.message,
      postType: 2, // message 1 or requestQuote 2 or offer my service 3
      messageStatus: 0,
      ownerId: selectedCard?.createdBy,
      Gift: form.gift ? 1 : 0,
    };
    await sendRequestQuote(formData);
    resetForm();
    closeModal();
    toast.success("Request sent");
    navigate("/dashboardpage/inbox/new-inbox");
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={closeModal}
      onRequestClose={closeModal}
      className="hire-modal"
      style={{
        content: {
          width: "60%",
          margin: "auto",
          backgroundColor: "#fff",
          padding: "40px",
          position: "absolute",
          top: "30px",
          left: "50%",
          transform: "translateX(-50%)",
          maxHeight: "80vh",
          overflowY: "scroll",
          border: "1px solid #B6B6B4",
          borderRadius: "10px",
        },
      }}
      size="lg"
    >
      <div className="d-flex justify-content-end">
        <button
          type="button"
          className="btn-close"
          onClick={() => {
            closeModal();
            setForm({
              ...form,
              gift: false,
            });
            setError("");
          }}
        ></button>
      </div>

      <div className="modal-body">
        <h5 className="pb-3">
          Request Appointment For {selectedCard?.listName}
        </h5>
        <p className="pb-3">{selectedCard?.userName}</p>
        <Row className="pb-3">
          <Col xs={12} md={3}>
            <Label>
              Date <span style={{ color: "red" }}>*</span>
            </Label>
            <Input
              type="date"
              min={new Date().toISOString().split("T")[0]}
              value={
                selectedDate
                  ? new Date(
                      selectedDate?.getTime() -
                        selectedDate?.getTimezoneOffset() * 60000
                    )
                      .toISOString()
                      .split("T")[0]
                  : ""
              }
              disabled={selectedDate}
            />
          </Col>
          <Col xs={12} md={3}>
            <Label>
              Start Time <span style={{ color: "red" }}>*</span>
            </Label>
            <Input
              type={quotePop ? "time" : "text"}
              name="startTime"
              value={form.startTime}
              onChange={(e) => setForm({ ...form, startTime: e.target.value })}
              disabled={form.startTime && !quotePop}
            />
          </Col>
          <Col xs={12} md={3}>
            <Label>
              End Time <span style={{ color: "red" }}>*</span>
            </Label>
            <Input
              type="time"
              onChange={(e) => setForm({ ...form, endTime: e.target.value })}
            />
          </Col>
          <Col xs={12} md={3}>
            <Label>
              Budget <span style={{ color: "red" }}>*</span>
            </Label>
            <Input
              type="number"
              placeholder="$00"
              onChange={(e) => setForm({ ...form, budget: e.target.value })}
            />
          </Col>
        </Row>
        <div className="appointment-gift my-3">
          <Row className=" d-flex align-items-center">
            <Col xs="auto p-0">
              <Input
                type="radio"
                name="gift"
                className="m-0"
                checked={form.gift}
                onClick={() =>
                  setForm((prevForm) => ({
                    ...prevForm,
                    gift: !prevForm.gift,
                  }))
                }
              />
            </Col>
            <Col className="text-primary fw-bold">
              {selectedCard.giftAmount ? (
                <Label>Claim Free Gift</Label>
              ) : (
                <Label>Claim Free Sample Service</Label>
              )}
            </Col>
          </Row>
          <h6>{selectedCard.giftTitle}</h6>
          <small>{selectedCard.giftDescription}</small>
        </div>
        <Row className="pb-3">
          <Label>Message</Label>
          <p>Describe what you expect from the service</p>
          <Input
            type="textarea"
            onChange={(e) => setForm({ ...form, message: e.target.value })}
          />
        </Row>

        <Row className="pb-3">
          <Label>Add Images</Label>
          <p>You can add images for reference (optional)</p>
          <div className="mt-2" style={{ display: "flex", flexWrap: "wrap" }}>
            {images.map((image, index) => (
              <Col sm={2} className="image-input m-1" key={index}>
                <Input
                  type="file"
                  id={`imageUpload${index + 1}`}
                  onChange={(e) => handleImageChange(index, e)}
                  style={{ display: "none" }}
                  name="images"
                />
                <div
                  className="image-box"
                  onClick={() =>
                    document.getElementById(`imageUpload${index + 1}`).click()
                  }
                >
                  {image && (
                    <>
                      <img
                        src={image}
                        alt={`Highlighted Image ${index + 1}`}
                        className="uploaded-image"
                        style={{ width: "100%" }}
                      />
                    </>
                  )}
                  {!image && <FaPlusCircle className="upload-icon" />}
                </div>
              </Col>
            ))}
          </div>
        </Row>
        {error && <Alert color="danger">{error}</Alert>}
        <div className="d-flex justify-content-end mt-3">
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => {
              handleRequest();
            }}
          >
            Request Appointment
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default RequestAppointment;
