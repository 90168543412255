import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  resetFormState,
  setCreatedBy,
  setCreatedEmail,
  setEmail,
  setEngagedCouple,
  setUpdatedBy,
} from "../../../redux/slices/RequestFormSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { updateRequest, postRequest } from "../../../components/Header/Data";
import { toast } from "react-toastify";
import { Row, Col, FormGroup, Label, Input } from "reactstrap";
import CreateNeed from "./CreateNeed";
import UpcomingEvent from "./UpcomingEvent";

const RequestNeed = ({
  categories,
  subCategories1,
  editMode,
  needId,
  selectedCat,
  setSelectedCat,
}) => {
  const dispatch = useDispatch();
  const userinfo = useSelector((state) => state.user.userInfo);
  const islogin = useSelector((state) => state.user.isLogin);
  const user = islogin ? JSON.parse(userinfo) : null;
  const [subCategories, setSubCategories] = useState([]);
  const isAdmin = user && user.Role === "admin"; // Check if user is admin
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const formData = useSelector((state) => state.requestForm);
  const location = useLocation();
  const tab = new URLSearchParams(location.search).get("tab");
  console.log(formData.upc);
  useEffect(() => {
    console.log(editMode, "editMode");
    if (islogin) {
      if (!needId) {
        dispatch(setCreatedBy(user.UserID));
        dispatch(setEmail(user.Email));
      } else {
        dispatch(setUpdatedBy(user.UserID));
      }
    }
  }, [editMode, user]);
  console.log(formData.engagedCouple, "engagedCouple");
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (isLoading) return;
    if (isAdmin && !formData?.Email) {
      toast.error("Please Enter Email ID");
      return;
    } else if (!formData?.FullName) {
      toast.error("Please Enter Full Name");
      return;
    } else if (
      (isAdmin || formData?.engagedCouple === 1) &&
      !formData?.categoryId
    ) {
      toast.error("Please Select Category");
      return;
    } else if (
      isAdmin &&
      subCategories?.length !== 0 &&
      !formData?.subCategoryId
    ) {
      toast.error("Please Select Sub Category");
      return;
    } else if (isAdmin && formData?.dialExtension === "") {
      toast.error("Please Enter Dial Extension");
      return;
    } else if (!formData?.Title) {
      toast.error("Please Enter Title");
      return;
    } else if (!formData?.Zipcode || formData?.Zipcode?.length < 5) {
      toast.error("Enter 5 digits Zip Code");
      return;
    } else if (!formData?.Phone) {
      toast.error("Enter Phone Number");
      return;
    } else if (formData?.Phone?.length < 10 && formData?.Phone?.length > 0) {
      toast.error("Enter valid Phone number");
      return;
    } else if (!formData?.alternativePhoneOne) {
      toast.error('"Mobile Number (Family Member)" is a required field.');
      return;
    } else if (
      formData?.alternativePhoneOne?.length < 10 &&
      formData?.alternativePhoneOne?.length > 0
    ) {
      toast.error("Enter valid Phone number");
      return;
    } else if (
      formData?.alternativePhoneTwo?.length < 10 &&
      formData?.alternativePhoneTwo?.length > 0
    ) {
      toast.error("Enter valid Phone number");
      return;
    } else if (!formData?.Terms) {
      toast.error("Accept the Terms & Conditions");
      return;
    }
    const updatedFormData = { ...formData };

    if (
      updatedFormData.availableFrom &&
      typeof updatedFormData.availableFrom !== "string"
    ) {
      updatedFormData.availableFrom = JSON.stringify(
        updatedFormData.availableFrom
      );
    }
    setIsLoading(true);
    if (Object.keys(editMode).length > 0) {
      await updateRequest(editMode.Id, updatedFormData);
      toast.success("Updated Request sent to Admin for Approval");
      {
        !islogin
          ? navigate("/Localcommunityads")
          : navigate("/dashboardpage/needs-requests");
      }
      window.scroll(0, 0);
    } else {
      await postRequest(updatedFormData);
      toast.success("New Request sent to Admin for Approval");
      {
        !islogin
          ? navigate("/Localcommunityads")
          : navigate("/dashboardpage/needs-requests");
      }
      window.scroll(0, 0);
    }
    dispatch(resetFormState());
    setIsLoading(false);
  };

  useEffect(() => {
    if (formData.engagedCouple == 1) {
      // add url params to the url
      const searchParams = new URLSearchParams(location.search);
      searchParams.set("tab", "upcoming-event");
      navigate(
        {
          pathname: location.pathname,
          search: searchParams.toString(),
        },
        { replace: true }
      );
    } else {
      const searchParams = new URLSearchParams(location.search);
      searchParams.set("tab", "normal-need");
      navigate(
        {
          pathname: location.pathname,
          search: searchParams.toString(),
        },
        { replace: true }
      );
    }
  }, [formData.engagedCouple]);
  console.log(formData, "formData");
  return (
    <div>
      <div className="mt-4 text-center">
        <div
          className="wc_post-add-btn d-inline-block"
          style={{
            backgroundColor: "#DB3545",
            padding: "10px",
            borderRadius: "10px",
            color: "#fff",
            gap: "7px",
          }}
        >
          <input
            type="checkbox"
            className="custom-checkbox"
            checked={formData?.engagedCouple == 1}
            onChange={(e) => {
              dispatch(setEngagedCouple(e.target.checked ? 1 : 0));
            }}
            name="engagedCouple"
          />
          <label className="ml-2">I have Upcoming Wedding/Event</label>
        </div>
      </div>
      {(!islogin || (islogin && isAdmin)) && (
        <Col className="request-form p-4 mt-3">
          {" "}
          <Row>
            <FormGroup>
              <Label>Enter Email ID</Label>{" "}
              {islogin && isAdmin && <b className="text-danger">*</b>}
              <Input
                type="email"
                name="Email"
                placeholder="Enter Email ID"
                value={formData.Email}
                onChange={(e) => dispatch(setEmail(e.target.value))}
              />
            </FormGroup>
          </Row>
        </Col>
      )}
      {formData.engagedCouple === 0 && (
        <CreateNeed
          categories={categories}
          subCategories1={subCategories1}
          subCategories={subCategories}
          setSubCategories={setSubCategories}
          editMode={editMode}
          handleFormSubmit={handleFormSubmit}
          selectedCat={selectedCat}
          setSelectedCat={setSelectedCat}
          isLoading={isLoading}
        />
      )}
      {formData.engagedCouple === 1 && (
        <UpcomingEvent
          categories={categories}
          subCategories1={subCategories1}
          subCategories={subCategories}
          setSubCategories={setSubCategories}
          editMode={editMode}
          handleFormSubmit={handleFormSubmit}
          selectedCat={selectedCat}
          setSelectedCat={setSelectedCat}
          isLoading={isLoading}
        />
      )}
    </div>
  );
};

export default RequestNeed;
