import { Menu, MenuItem } from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { saveAs } from "file-saver";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const DownloadAds = () => {
  const { Lca } = useSelector((state) => state.lcAds);
  const [anchorEl, setAnchorEl] = useState(null);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const navigate = useNavigate();

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDownload = () => {
    const filteredData =
      Lca &&
      Lca.filter((item) => {
        const itemDate = new Date(item.createdAt);
        const start = new Date(startDate);
        const end = new Date(endDate);
        return itemDate >= start && itemDate <= end;
      });
    if (filteredData.length === 0) {
      toast.info("No data found for the selected date range.");
      return;
    }
    const processedData = filteredData.map((item) => ({
      ...item,
      eventDetails: formatArray(item.eventDetails),
      availableFrom: formatArray(item.availableFrom),
    }));

    const csvHeaders = Object.keys(processedData[0]).join(",");
    const csvRows = processedData.map((row) =>
      Object.values(row)
        .map((value) =>
          typeof value === "string" ? `"${value.replace(/"/g, '""')}"` : value
        )
        .join(",")
    );
    const csvContent = [csvHeaders, ...csvRows].join("\n");

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const formattedStartDate = startDate
      ? startDate.replace(/-/g, "")
      : "start";
    const formattedEndDate = endDate ? endDate.replace(/-/g, "") : "end";
    const fileName = `ads_${formattedStartDate}_to_${formattedEndDate}.csv`;

    saveAs(blob, fileName);

    handleClose();
  };

  const formatArray = (arrayString) => {
    try {
      const array = JSON.parse(arrayString);
      if (Array.isArray(array)) {
        return array.map((entry) => JSON.stringify(entry)).join("; ");
      }
      return arrayString;
    } catch (error) {
      return arrayString;
    }
  };

  return (
    <div aria-hidden={true}>
      <span onClick={handleClick}>Download Ads</span>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PaperProps={{
          style: {
            minWidth: "300px",
            borderRadius: "12px",
            padding: "20px",
            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
          },
        }}
      >
        <div style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
          <label
            style={{
              display: "flex",
              flexDirection: "column",
              fontSize: "14px",
              fontWeight: "500",
            }}
          >
            Start Date:
            <input
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              style={{
                padding: "8px",
                borderRadius: "5px",
                border: "1px solid #ccc",
                marginTop: "5px",
              }}
            />
          </label>
          <label
            style={{
              display: "flex",
              flexDirection: "column",
              fontSize: "14px",
              fontWeight: "500",
            }}
          >
            End Date:
            <input
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              style={{
                padding: "8px",
                borderRadius: "5px",
                border: "1px solid #ccc",
                marginTop: "5px",
              }}
            />
          </label>
          <button
            onClick={handleDownload}
            style={{
              backgroundColor: "#28A745",
              color: "white",
              padding: "10px",
              border: "none",
              borderRadius: "5px",
              fontSize: "16px",
              fontWeight: "bold",
              cursor: "pointer",
              textAlign: "center",
            }}
          >
            Download
          </button>
        </div>
        {/* clear dates on right corner */}
        <div
          style={{
            position: "absolute",
            top: "10px",
            right: "10px",
            cursor: "pointer",
            color: "blue",
          }}
          onClick={() => {
            setStartDate("");
            setEndDate("");
          }}
        >
          Clear
        </div>
      </Menu>
    </div>
  );
};

export default DownloadAds;
