import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GetAllTemplates } from "../../components/Header/Data3";

export const fetchTemplates = createAsyncThunk(
  "message/ListAllMessageTemplate",
  async () => {
    const response = await GetAllTemplates();
    return response; // Directly return all templates
  }
);

const initialState = {
  AllTemplates: [],
  myTemplates: [],
  pendingTemps: [],
  ActiveTemps: [],
  loading: false,
  error: null,
};

const TemplateSlice = createSlice({
  name: "templates",
  initialState,
  reducers: {
    setAllTemplates(state, action) {
      state.AllTemplates = action.payload;
    },
    setMyTemplates(state, action) {
      state.myTemplates = action.payload;
    },
    setPendingTemps(state, action) {
      state.pendingTemps = action.payload;
    },
    setActiveTemps(state, action) {
      state.ActiveTemps = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTemplates.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchTemplates.fulfilled, (state, action) => {
        state.loading = false;
        state.AllTemplates = action.payload;
      })
      .addCase(fetchTemplates.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { setAllTemplates, setMyTemplates, setPendingTemps, setActiveTemps } =
  TemplateSlice.actions;
export default TemplateSlice.reducer;
