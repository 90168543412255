import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  borderRadius: "5px",
  border: "none",
  boxShadow: 24,
  p: 2,
};

export default function InviteCreateModal({ open, toggle, setOpenPostAd }) {
  return (
    <div>
      <Modal
        keepMounted
        open={open}
        onClose={toggle}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={style}>
          {/* <Typography id="keep-mounted-modal-title" variant="h6" component="h2">
            Text in a modal
          </Typography> */}
          <Typography id="keep-mounted-modal-description" sx={{ mt: 2 }}>
            Post your own listing of what you’re looking for
          </Typography>
          <Button
            onClick={() => {
              setOpenPostAd(true);
              toggle();
            }}
            variant="contained"
            sx={{
              mt: 2,
              textAlign: "center",
            }}
            color="primary"
          >
            Post Your Ad
          </Button>
        </Box>
      </Modal>
    </div>
  );
}
