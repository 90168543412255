import React, { useState } from "react";
import {
  FaEnvelope,
  FaFileAlt,
  FaRegComment,
  FaRegEnvelope,
  FaRegStar,
  FaChevronRight,
  FaChevronDown,
  FaSearch,
  FaTimes,
  FaSearchPlus,
} from "react-icons/fa";
import FilterIcon from "./sliders.svg";
import { Menu, MenuItem, Collapse } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  setEventDateSearch,
  setGuestCount,
  setMeetingDateSearch,
  setSearchTerm,
} from "../../../redux/slices/MsgSlice";

const SearchFilter = ({ clearSearch }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [expandedMenu, setExpandedMenu] = useState(null); // Track expanded menu
  const { searchTerm, meetingDateSearch, eventDateSearch, guestCount } =
    useSelector((state) => state.messages);
  const dispatch = useDispatch();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setExpandedMenu(null); // Close any expanded submenus when the main menu closes
  };
  const clearfilterOptions = () => {
    dispatch(setMeetingDateSearch(""));
    dispatch(setEventDateSearch(""));
    dispatch(setGuestCount(""));
    handleClose();
  };
  const toggleSubMenu = (menu) => {
    setExpandedMenu(expandedMenu === menu ? null : menu); // Toggle the submenu
  };
  const handleMeetingDateChange = (event) => {
    dispatch(setMeetingDateSearch(event.target.value));
    // handleClose();
  };
  const handleEventDateChange = (event) => {
    dispatch(setEventDateSearch(event.target.value));
    // handleClose();
  };
  const handleCount = (event) => {
    dispatch(setGuestCount(event.target.value));
  };

  console.log(meetingDateSearch, "meetingDateSearch");
  return (
    <div className="search-bar-container">
      <FaSearch className="search-icon" />
      <input
        type="text"
        placeholder="Search conversations..."
        value={searchTerm}
        onChange={(e) => dispatch(setSearchTerm(e.target.value))}
        className="search-input"
      />
      {searchTerm && <FaTimes className="clear-icon" onClick={clearSearch} />}
      <img
        src={FilterIcon}
        alt="Filter Icon"
        className="filter-icon ms-1"
        onClick={(event) => handleClick(event)}
      />
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PaperProps={{
          style: {
            minWidth: "200px", // Adjust width as needed
            borderRadius: "10px",
          },
        }}
      >
        <MenuItem
          onClick={clearfilterOptions}
          className="wc-msg-filter-menu-item bg-danger text-white"
        >
          <FaChevronRight
            style={{ marginRight: "10px", visibility: "hidden" }}
          />
          <span style={{ flexGrow: 1 }}>Clear Filters</span>
        </MenuItem>
        <MenuItem
          onClick={handleClose}
          className="wc-msg-filter-menu-item"
          disabled
        >
          <FaChevronRight
            style={{ marginRight: "10px", visibility: "hidden" }}
          />
          <span style={{ flexGrow: 1 }}>Unread</span>
          <FaRegEnvelope />
        </MenuItem>
        <MenuItem
          onClick={handleClose}
          className="wc-msg-filter-menu-item"
          disabled
        >
          <FaChevronRight
            style={{ marginRight: "10px", visibility: "hidden" }}
          />
          <span style={{ flexGrow: 1 }}>Favorites</span>
          <FaRegStar />
        </MenuItem>
        <MenuItem
          onClick={() => toggleSubMenu("meetingDate")}
          className={`wc-msg-filter-menu-item ${
            expandedMenu === "meetingDate" ? "selectedMenu" : ""
          }`}
        >
          {expandedMenu === "meetingDate" ? (
            <FaChevronDown style={{ marginRight: "10px" }} />
          ) : (
            <FaChevronRight style={{ marginRight: "10px" }} />
          )}
          <span style={{ flexGrow: 1 }}>Search meeting Date</span>
        </MenuItem>
        <Collapse in={expandedMenu === "meetingDate"}>
          <MenuItem className="wc-msg-filter-menu-item">
            <span style={{ paddingLeft: "20px" }}>
              <input
                type="date"
                placeholder="Date"
                className="meeting-date-input"
                value={meetingDateSearch}
                onChange={handleMeetingDateChange}
              />
            </span>
          </MenuItem>
        </Collapse>
        <MenuItem
          onClick={() => toggleSubMenu("eventDate")}
          className={`wc-msg-filter-menu-item ${
            expandedMenu === "eventDate" ? "selectedMenu" : ""
          }`}
        >
          {expandedMenu === "eventDate" ? (
            <FaChevronDown style={{ marginRight: "10px" }} />
          ) : (
            <FaChevronRight style={{ marginRight: "10px" }} />
          )}
          <span style={{ flexGrow: 1 }}>Search event Date</span>
        </MenuItem>
        <Collapse in={expandedMenu === "eventDate"}>
          <MenuItem className="wc-msg-filter-menu-item">
            <span style={{ paddingLeft: "20px" }}>
              <input
                type="date"
                placeholder="Date"
                className="meeting-date-input"
                value={eventDateSearch}
                onChange={handleEventDateChange}
              />
            </span>
          </MenuItem>
        </Collapse>
        {/* guestCount Parent Menu */}
        <MenuItem
          onClick={() => toggleSubMenu("guestCount")}
          className={`wc-msg-filter-menu-item ${
            expandedMenu === "guestCount" ? "selectedMenu" : ""
          }`}
        >
          {/* Chevron on the left */}
          {expandedMenu === "guestCount" ? (
            <FaChevronDown style={{ marginRight: "10px" }} />
          ) : (
            <FaChevronRight style={{ marginRight: "10px" }} />
          )}
          <span style={{ flexGrow: 1 }}>Guest Count</span>
          <FaFileAlt />
        </MenuItem>
        <Collapse in={expandedMenu === "guestCount"}>
          <span style={{ display: "flex", justifyContent: "center" }}>
            <input
              type="number"
              placeholder="count"
              className="guest-count-input"
              value={guestCount}
              onChange={handleCount}
            />
          </span>
        </Collapse>

        {/* Other Parent Menu */}
        <MenuItem
          onClick={() => toggleSubMenu("other")}
          className={`wc-msg-filter-menu-item ${
            expandedMenu === "other" ? "selectedMenu" : ""
          }`}
          disabled
        >
          {/* Chevron on the left */}
          {expandedMenu === "other" ? (
            <FaChevronDown style={{ marginRight: "10px" }} />
          ) : (
            <FaChevronRight style={{ marginRight: "10px" }} />
          )}
          <span style={{ flexGrow: 1 }}>Other</span>
          <FaRegComment />
        </MenuItem>
        <Collapse in={expandedMenu === "other"}>
          <MenuItem onClick={handleClose} className="wc-msg-filter-menu-item">
            <span style={{ paddingLeft: "20px" }}>Other 1</span>
          </MenuItem>
          <MenuItem onClick={handleClose} className="wc-msg-filter-menu-item">
            <span style={{ paddingLeft: "20px" }}>Other 2</span>
          </MenuItem>
        </Collapse>
      </Menu>
    </div>
  );
};

export default SearchFilter;
