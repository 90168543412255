import { styled, ToggleButton } from "@mui/material";

export const CustomToggleButton = styled(ToggleButton)(({ theme }) => ({
  borderRadius: "20px",
  padding: "1px 8px",
  fontSize: "12px",
  backgroundColor: "#14A800",
  color: theme.palette.common.white,
  "&.Mui-selected": {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.grey[900], // Ensure selected text color stands out
  },
  "&:hover": {
    backgroundColor: "#14A800", // Define hover color for unselected buttons
  },
}));
