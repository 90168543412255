import React from "react";
import "./messages.css";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getConvoById } from "../../../components/Header/Data2";
import moment from "moment";

function MessageItem({ conversation, onSelectConversation }) {
  const selectedConversation = useSelector(
    (state) => state.messages.selectedConvo
  );
  const { searchTerm, meetingDateSearch } = useSelector(
    (state) => state.messages
  );
  const islogin = useSelector((state) => state.user.isLogin);
  const userinfo = useSelector((state) => state.user.userInfo);
  const user = islogin ? JSON.parse(userinfo) : null;
  const navigate = useNavigate();
  const [latest, setLatest] = React.useState(null);
  const [unreadCount, setUnreadCount] = React.useState(0);

  const ViewProfile = (msg) => {
    if (msg.createdBy === user.UserID) {
      navigate(`/userDetails/${msg.customerId}`, { state: msg.ownerId });
    } else {
      navigate(`/userDetails/${msg.createdBy}`, { state: msg.ownerId });
    }
  };
  const search = searchTerm.toLowerCase() || meetingDateSearch.toLowerCase();
  const searchResults =
    search && conversation
      ? [
          conversation.senderName,
          conversation.customerName,
          conversation.senderEmail,
          conversation.customerEmail,
          conversation.providerPhone,
          conversation.customerPhone,
          conversation.reserveDate,
        ]
          .filter((field) =>
            field?.toLowerCase().includes(search.toLowerCase())
          )
          .join(", ")
      : null;

  const fetchMsgs = async () => {
    if (conversation.Id) {
      const messagesData = await getConvoById(conversation?.Id);
      setLatest(messagesData[0]);
      const filtercount = messagesData.filter(
        (msg) => msg.createdBy == user.UserID && msg.seenCustomer == 0
      );
      setUnreadCount(filtercount.length);
      console.log(filtercount, "filtercount");
    }
  };
  React.useEffect(() => {
    fetchMsgs();
  }, [conversation]);
  function formatDate(inputDate) {
    let date = new Date(inputDate);
    let monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "July",
      "Aug",
      "Sept",
      "Oct",
      "Nov",
      "Dec",
    ];
    // let year = date.getFullYear();
    let month = monthNames[date.getMonth()];
    let day = date.getDate();
    let formattedDay = day < 10 ? "0" + day : day;
    let formattedDate = `${month} ${formattedDay}`;
    return formattedDate;
  }
  function convertToUserTimezone(utcTime) {
    let timezone = "America/Los_Angeles"; // Default to system timezone
    if (islogin && user?.TimeZone) {
      timezone = user?.TimeZone.split(",")[0];
    }
    const localTime = moment
      .utc(utcTime)
      .tz(timezone)
      .format("YYYY-MM-DD HH:mm:ss");
    return localTime;
  }
  function convertTo12HourFormat(time24) {
    if (!time24) {
      return "";
    }
    const [hour24, minute] = time24.split(":").map(Number);
    if (isNaN(hour24) || isNaN(minute)) {
      return "";
    }
    const period = hour24 >= 12 ? "PM" : "AM";
    const hour12 = (hour24 % 12 || 12).toString().padStart(2, "0"); // Added padStart
    return `${hour12}:${minute.toString().padStart(2, "0")} ${period}`;
  }
  console.log(conversation.Id, unreadCount, "unreadCount.length");
  return (
    <div
      className={`message-item ${unreadCount > 0 ? "unread" : ""} ${
        selectedConversation && selectedConversation.Id === conversation.Id
          ? "selected"
          : ""
      }`}
      onClick={() => onSelectConversation(conversation.Id, conversation)}
    >
      <hr />
      <div>
        {conversation.createdBy !== user.UserID ? (
          <>
            <img
              src={
                conversation?.senderPhoto !== null &&
                conversation?.senderPhoto !== ""
                  ? conversation?.senderPhoto
                  : "https://st3.depositphotos.com/6672868/13701/v/450/depositphotos_137014128-stock-illustration-user-profile-icon.jpg"
              }
              alt=""
              className="rounded-circle border border-1 border-secondary bg-light avatar shadow p-1 mb-1 d-inline-block"
              width={40}
              height={40}
              onClick={() => ViewProfile(conversation)}
            />
          </>
        ) : (
          <>
            <img
              src={
                conversation?.receiverProfilePhoto !== null &&
                conversation?.receiverProfilePhoto !== ""
                  ? conversation?.receiverProfilePhoto
                  : "https://st3.depositphotos.com/6672868/13701/v/450/depositphotos_137014128-stock-illustration-user-profile-icon.jpg"
              }
              onClick={() => ViewProfile(conversation)}
              alt={conversation?.userName}
              className="rounded-circle border border-1 border-secondary bg-light avatar shadow p-1 mb-1 d-inline-block"
              width={40}
              height={40}
            />
          </>
        )}
      </div>
      <div className="message-info">
        <div className="name">
          {" "}
          {conversation.createdBy === user.UserID ? (
            <strong
              // onClick={() => ViewProfile(conversation)}
              style={{ fontSize: "small", cursor: "pointer" }}
            >
              <span>{conversation?.customerName}</span>
            </strong>
          ) : (
            <strong
              style={{ fontSize: "small", cursor: "pointer" }}
              // onClick={() => ViewProfile(conversation)}
            >
              <span>
                {conversation?.senderName &&
                conversation?.senderName.length > 15
                  ? `${conversation?.senderName.slice(0, 15)}...`
                  : conversation?.senderName}
              </span>
            </strong>
          )}
        </div>
        <div className="last-message">
          {conversation.seenCustomer === 0 ||
          conversation.seenProvider === 0 ? (
            <strong>
              {latest?.Message && latest.Message.length > 20
                ? `${latest.Message.slice(0, 20)}...`
                : latest?.Message}
            </strong>
          ) : latest?.Message && latest.Message.length > 20 ? (
            `${latest.Message.slice(0, 20)}...`
          ) : (
            latest?.Message
          )}
        </div>
        <div className="search-result-title">
          {searchResults && searchResults}
        </div>
      </div>
      {/* <div className="message-date">2024-08</div> */}
      <div
        className={`message-date mt-1 ${
          latest?.createdBy === user.UserID ? "me-1" : "ms-1"
        }`}
      >
        <span className="me-2">
          {formatDate(convertToUserTimezone(latest?.createdAt?.split(" ")[0]))}
        </span>
        <span>
          {convertTo12HourFormat(
            convertToUserTimezone(latest?.createdAt?.split(" ")[1])
          )}
        </span>
      </div>
      {(conversation.seenCustomer == 0 || conversation.seenProvider == 0) && (
        <div className="unread-count">{unreadCount}</div>
      )}
    </div>
  );
}

export default MessageItem;
