import React, { useEffect, useState } from "react";
import { Button, Form, Col, Input, Row, FormGroup, Label } from "reactstrap";
import Sidebar from "../../SideBar";
import "./Gurdwara.css";
import { useLocation, useNavigate } from "react-router-dom";
import { FaArrowLeft, FaPlusCircle, FaTrashAlt } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { countryCodes } from "../../../components/CountryCodes";
import { toast } from "react-toastify";
import Select from "react-select";
import GurdwaraService from "./GurdwaraService";
import uploadToS3 from "../../../utils/awsS3Upload";
import {
  addGurudwara,
  detailsGurudwara,
  updateGurudwara,
} from "../../../components/Header/Data";

const CreateGurdwara = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state;
  const [formData, setFormData] = useState({
    Title: "",
    Address: "",
    Description: "",
    paypalClientID: "",
    paypalSecretKey: "",
    timeZone: "America/Los_Angeles, Pacific Time",
    serviceInfo: "",
    additionalPeople: "",
    seoTitle: "",
    seoKeyword: "",
    seoDescription: "",
    Photo: "",
    tollFreeNumber: null,
    Term: "",
  });
  const [loading, setLoading] = useState(false); // New loading state
  const handleChangeForm = (e) => {
    const { name, value } = e.target;

    if (name === "tollFreeNumber") {
      const formattedValue = value.replace(/\D/g, "").slice(0, 10);
      setFormData((prevData) => ({
        ...prevData,
        tollFreeNumber: formattedValue,
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };
  console.log(formData, "formData");
  const [highlightedImages, setHighlightedImages] = useState(
    Array(7).fill(null)
  );
  const [blobImages, setBlobImages] = useState(Array(7).fill(null));
  const handleImageChange = (index, event) => {
    const files = event.target.files;
    if (files.length > 0) {
      const imageFile = files[0];
      // Set the file directly in highlightedImages
      setBlobImages((prevImages) => {
        const newImages = [...prevImages];
        newImages[index] = imageFile; // Store the file
        return newImages;
      });
      const reader = new FileReader();
      reader.onloadend = () => {
        // Set the blob URL in blobImages
        setHighlightedImages((prevBlobImages) => {
          const newBlobImages = [...prevBlobImages];
          newBlobImages[index] = reader.result; // Store the blob URL
          return newBlobImages;
        });
      };
      reader.readAsDataURL(imageFile);
    }
  };

  console.log(blobImages, "blobImages");
  const userinfo = useSelector((state) => state.user.userInfo);
  const islogin = useSelector((state) => state.user.isLogin);
  const user = islogin ? JSON.parse(userinfo) : null;

  const [rows, setRows] = useState([
    {
      email: "",
      phoneNumber: "",
      countryCode: "+1",
    },
  ]);

  const countryOptions = countryCodes.map((country) => ({
    label: `${country.name} (${country.dial_code})`,
    value: country.dial_code,
  }));
  const id = user?.Gurudwara;
  const fetchDetailsGurdwara = async () => {
    const response = await detailsGurudwara(id);
    console.log(response.Data[0], "response");
    setFormData(response.Data[0]);
  };

  useEffect(() => {
    fetchDetailsGurdwara();
    if (state.additionalPeople) {
      const stateParsedData = JSON.parse(state.additionalPeople);
      setRows(stateParsedData);
      console.log(stateParsedData, "AdditionalPeople");
    } else {
      setRows([
        {
          email: "",
          phoneNumber: "",
          countryCode: "+1",
        },
      ]);
    }
  }, []);
  useEffect(() => {
    const jsonPhoto =
      state.Photo && typeof state.Photo === "string" && JSON.parse(state.Photo);
    if (jsonPhoto?.length > 0) {
      setHighlightedImages(
        jsonPhoto.concat(Array(7 - jsonPhoto.length).fill(null))
      );
    } else {
      setHighlightedImages(Array(7).fill(null));
    }
  }, [formData.Photo]);

  const handleChange = (value, index, field) => {
    const updatedRows = [...rows];

    if (field === "phoneNumber") {
      const formattedValue = value.replace(/\D/g, "").slice(0, 10);
      updatedRows[index] = {
        ...updatedRows[index],
        [field]: formattedValue,
      };
    } else {
      updatedRows[index] = {
        ...updatedRows[index],
        [field]: value,
      };
    }
    setRows(updatedRows);

    // Update the formData's additionalPeople field
    setFormData((prevData) => ({
      ...prevData,
      additionalPeople: JSON.stringify(updatedRows), // Ensure this is a string
    }));
  };

  const handleAddData = () => {
    const lastRow = rows[rows.length - 1];
    const isEmpty = Object.values(lastRow).some((value) => value === "");
    const isPhoneNumberValid = lastRow.phoneNumber.length === 10; // Check for 10 digits

    if (isEmpty) {
      toast.error("Please fill in all details");
      return;
    }

    if (rows.length >= 5) {
      return;
    }

    if (!isPhoneNumberValid) {
      toast.error("Enter Valid Phone number");
      return;
    }

    // Update rows state to add a new row
    setRows((prevRows) => [
      ...prevRows,
      {
        email: "",
        phoneNumber: "",
        countryCode: "+1",
      },
    ]);

    // Update additionalPeople in formData with the new rows array
    setFormData((prevData) => ({
      ...prevData,
      additionalPeople: JSON.stringify([
        ...rows,
        { email: "", phoneNumber: "", countryCode: "+1" },
      ]),
    }));
  };

  const handleDeleteRow = (index) => {
    if (rows.length === 1) {
      setRows([
        {
          email: "",
          phoneNumber: "",
          countryCode: "+1",
        },
      ]);
    } else {
      const updatedRows = [...rows];
      if (index !== rows.length - 1) {
        updatedRows.splice(index, 1);
      } else {
        updatedRows[index] = {
          email: "",
          phoneNumber: "",
          countryCode: "+1",
        };
      }
      setRows(updatedRows);
    }
  };

  console.log(blobImages, "blobImages");
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formData.Address) {
      toast.error("Please Enter Address");
      return;
    }
    if (!formData.Description) {
      toast.error("Please Enter Description");
      return;
    }
    if (!formData.tollFreeNumber) {
      toast.error("Please Enter Toll Free Number");
      return;
    }
    if (
      formData?.tollFreeNumber?.length < 10 &&
      formData?.tollFreeNumber?.length > 0
    ) {
      toast.error("Enter valid Toll Free Number");
      return;
    }
    if (!formData.paypalClientID) {
      toast.error("Please Enter Paypal Client ID");
      return;
    }
    if (!formData.paypalSecretKey) {
      toast.error("Please Enter Paypal Secret Key");
      return;
    }
    if (!formData.timeZone) {
      formData.timeZone = "America/Los_Angeles, Pacific Time";
    }
    if (!formData.serviceInfo) {
      toast.error("Please Enter Serive Information");
      return;
    }
    if (!formData.additionalPeople) {
      toast.error("Please Enter Additional People");
      return;
    }
    if (!formData.Term) {
      toast.error("Please Enter Terms Conditions");
      return;
    }
    if (!formData.seoTitle) {
      toast.error("Please Enter SEO Information");
      return;
    }
    if (!formData.seoDescription) {
      toast.error("Please Enter SEO Information");
      return;
    }
    if (!formData.seoKeyword) {
      toast.error("Please Enter SEO Information");
      return;
    }
    const isEmptyRow = rows.some((row) =>
      Object.values(row).some((value) => value === "")
    );
    if (isEmptyRow) {
      toast.error("Please fill in all additional people details");
      return;
    }
    setLoading(true);
    const imagesToUpload = blobImages.filter((blob) => blob !== null);
    let folder = "gurdwara/";
    let imageUrls = [];
    const existingImageUrls = formData.Photo ? JSON.parse(formData.Photo) : [];
    if (imagesToUpload.length > 0) {
      try {
        const newImageUrls = await Promise.all(
          imagesToUpload.map(async (blob) => {
            return await uploadToS3(blob, folder);
          })
        );
        imageUrls = [...existingImageUrls, ...newImageUrls];
        console.log("Uploaded Image URLs:", imageUrls);

        // Update formData with the uploaded image URLs
        setFormData((prevData) => ({
          ...prevData,
          Photo: JSON.stringify(imageUrls), // Store as a stringified array
        }));
      } catch (error) {
        console.error("Error uploading images:", error);
        toast.error("Failed to upload images. Please try again.");
        setLoading(false);
        return;
      }
    } else {
      // If no new images are uploaded, keep existing URLs
      imageUrls = existingImageUrls;
    }

    // Continue with the submission process
    const details = {
      Title: formData.Title,
      Description: formData.Description,
      Address: formData.Address,
      //paypalId: formData.paypalId,
      serviceInfo: formData.serviceInfo,
      additionalPeople: formData.additionalPeople,
      seoTitle: formData.seoTitle,
      Photo: JSON.stringify(imageUrls), // Use the combined URLs
      gurudwaraStatus: 0,
      seoDescription: formData.seoDescription,
      seoKeyword: formData.seoKeyword,
      updatedBy: user?.UserID,
      tollFreeNumber: formData?.tollFreeNumber,
      Term: formData?.Term,
      paypalClientID: formData?.paypalClientID,
      paypalSecretKey: formData?.paypalSecretKey,
      timeZone: formData?.timeZone || "America/Los_Angeles, Pacific Time",
    };

    console.log(details, "details");

    try {
      await updateGurudwara(id, details);
      toast.success(`${formData.Title} is Added`);
      navigate("/dashboardpage/gurdwaramanagement");
    } catch (e) {
      console.error("Failed to add Gurudwara:", e);
      toast.error("Gurudwara already exists");
    } finally {
      setLoading(false);
    }
  };

  return (
    <section className="home-after-login bg-white">
      <div className="container">
        <Row>
          <Col sm={4} lg={3}>
            <Sidebar />
          </Col>
          <Col className="mt-3 inter-font">
            <Form onSubmit={handleSubmit}>
              <span
                className="mb-3 fs-5 text-dark"
                onClick={() => {
                  navigate(-1);
                }}
                style={{ cursor: "pointer" }}
              >
                <FaArrowLeft /> Back
              </span>
              <div className="text-center fs-4 fw-bolder mb-5">
                Gurudwara Information
              </div>
              <div style={{ marginLeft: "12%", width: "80%" }}>
                <FormGroup row>
                  <Label for="name" sm={6}>
                    Name<b className="text-danger">*</b>
                  </Label>

                  <Col sm={6}>
                    <Input
                      type="text"
                      name="Title"
                      id="name"
                      value={formData.Title}
                      onChange={handleChangeForm}
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label for="address" sm={6}>
                    Address<b className="text-danger">*</b>
                  </Label>

                  <Col sm={6}>
                    <Input
                      type="text"
                      name="Address"
                      id="address"
                      value={formData.Address}
                      onChange={handleChangeForm}
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label for="description" sm={6}>
                    Description<b className="text-danger">*</b>
                  </Label>

                  <Col sm={6}>
                    <Input
                      type="textarea"
                      name="Description"
                      id="description"
                      value={formData.Description}
                      onChange={handleChangeForm}
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label for="description" sm={6}>
                    Gurdwara Number<b className="text-danger">*</b>
                  </Label>
                  <Col sm={6}>
                    <Input
                      type="text"
                      name="tollFreeNumber"
                      id="tollFreeNumber"
                      value={formData.tollFreeNumber}
                      onChange={handleChangeForm}
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label for="paypalClientID" sm={6}>
                    PayPal Client ID<b className="text-danger">*</b>
                  </Label>

                  <Col sm={6}>
                    <Input
                      type="text"
                      name="paypalClientID"
                      id="paypalClientID"
                      value={formData?.paypalClientID}
                      onChange={handleChangeForm}
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label for="paypalSecretKey" sm={6}>
                    PayPal Secret Key<b className="text-danger">*</b>
                  </Label>

                  <Col sm={6}>
                    <Input
                      type="text"
                      name="paypalSecretKey"
                      id="paypalSecretKey"
                      value={formData?.paypalSecretKey}
                      onChange={handleChangeForm}
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label for="Timezone" sm={6}>
                    Timezone<b className="text-danger">*</b>
                  </Label>

                  <Col sm={6}>
                    <Input
                      type="select"
                      name="timeZone"
                      value={formData?.timeZone}
                      onChange={handleChangeForm}
                    >
                      <option value="America/New_York, Eastern Time">
                        America/New_York (Eastern Time)
                      </option>
                      <option value="America/Chicago, Central Time">
                        America/Chicago (Central Time)
                      </option>
                      <option value="America/Denver, Mountain Time">
                        America/Denver (Mountain Time)
                      </option>
                      <option value="America/Los_Angeles, Pacific Time">
                        America/Los_Angeles (Pacific Time)
                      </option>
                      <option value="UTC, Coordinated Universal Time">
                        UTC (Coordinated Universal Time)
                      </option>
                      <option value="Europe/London, GMT">
                        Europe/London (GMT)
                      </option>
                      <option value="Europe/Berlin, Central European Time">
                        Europe/Berlin (Central European Time)
                      </option>
                      {/* Add more options as needed */}
                    </Input>
                  </Col>
                </FormGroup>
              </div>
              <div style={{ marginLeft: "3%" }}>
                <FormGroup row>
                  <div
                    className="mb-3 mt-4 service_info px-3 py-2"
                    style={{ width: "97.5%", marginLeft: "1.3%" }}
                  >
                    <GurdwaraService
                      formData1={formData}
                      setFormData1={setFormData}
                    />
                  </div>
                </FormGroup>
                <FormGroup row>
                  <div
                    className="my-3 service_info px-3 pt-3 pb-4"
                    style={{ width: "97.5%", marginLeft: "1.3%" }}
                  >
                    <Label className="my-2 fs-5">Additional People:</Label>
                    <Row className="d-flex flex-row">
                      <Col sm={4} className="d-none d-lg-block">
                        Email
                      </Col>
                      <Col sm={3} className="d-none d-lg-block">
                        Phone Number
                      </Col>
                      <Col sm={3} className="d-none d-lg-block">
                        Country Code
                      </Col>
                      <Col sm={2} className="d-none d-lg-block"></Col>
                    </Row>
                    {rows.map((row, index) => (
                      <Row key={index} className="mt-3">
                        <Col sm={4} xs="12">
                          <Label className="d-block d-lg-none">Email</Label>
                          <Input
                            type="email"
                            name="email"
                            value={row.email}
                            onChange={(e) =>
                              handleChange(e.target.value, index, "email")
                            }
                          />
                        </Col>
                        <Col sm={3} xs="12">
                          <Label className="d-block d-lg-none">
                            Phone Number
                          </Label>
                          <Input
                            type="text"
                            name="phoneNumber"
                            value={row.phoneNumber}
                            onChange={(e) =>
                              handleChange(e.target.value, index, "phoneNumber")
                            }
                            maxLength={10}
                          />
                        </Col>
                        <Col sm={3} xs="12">
                          <Label className="d-block d-lg-none">
                            Country Code
                          </Label>
                          <Select
                            options={countryOptions}
                            name="countryCode"
                            placeholder="Select"
                            value={{
                              label: row.countryCode,
                              value: row.countryCode,
                            }}
                            onChange={(selectedOption) => {
                              handleChange(
                                selectedOption.value,
                                index,
                                "countryCode"
                              );
                            }}
                            className="w-100 p-0"
                          />
                        </Col>
                        <Col
                          sm="2"
                          xs="12"
                          className="d-flex align-items-center justify-content-center m-0 gap-4"
                        >
                          <FaTrashAlt
                            style={{ cursor: "pointer" }}
                            onClick={() => handleDeleteRow(index)}
                          />
                          {index === rows.length - 1 && (
                            <FaPlusCircle
                              size={23}
                              style={{ cursor: "pointer", color: "blue" }}
                              onClick={handleAddData}
                            />
                          )}
                        </Col>
                      </Row>
                    ))}
                  </div>
                </FormGroup>
                <FormGroup row style={{ marginLeft: "2%" }}>
                  <Label className="my-2 fs-5">Images</Label>
                  <Col>
                    <Row className="mb-3">
                      {highlightedImages.map((image, index) => (
                        <Col className="image-input" key={index}>
                          <FormGroup>
                            <Input
                              type="file"
                              id={`imageUpload${index + 1}`}
                              onChange={(e) => handleImageChange(index, e)}
                              style={{ display: "none" }}
                              name="Photo"
                            />
                          </FormGroup>
                          <div
                            className="image-box"
                            onClick={() =>
                              document
                                .getElementById(`imageUpload${index + 1}`)
                                .click()
                            }
                            style={{ display: "flex", flexWrap: "wrap" }}
                          >
                            {image && (
                              <img
                                src={image}
                                alt={`Highlighted Image ${index + 1}`}
                                className="uploaded-image image-fluid rounded"
                                style={{ width: "80px", height: "80px" }}
                              />
                            )}
                            {!image && <FaPlusCircle className="upload-icon" />}
                          </div>
                        </Col>
                      ))}
                    </Row>
                  </Col>
                </FormGroup>
              </div>
              <FormGroup row>
                <Label for="description" style={{ marginLeft: "6%" }}>
                  Terms and Conditions<b className="text-danger">*</b>
                </Label>
                <Col>
                  <Input
                    type="textarea"
                    name="Term"
                    id="Term"
                    value={formData.Term}
                    onChange={handleChangeForm}
                    style={{ width: "91%", marginLeft: "6%" }}
                  />
                </Col>
              </FormGroup>
              <div className="text-center fs-4 fw-bolder my-3 mb-4">
                SEO Information
              </div>
              <div style={{ marginLeft: "12%", width: "81%" }}>
                <FormGroup row>
                  <Label for="seoTitle" sm={6}>
                    Title<b className="text-danger">*</b>
                  </Label>
                  <Col sm={6}>
                    <Input
                      type="text"
                      name="seoTitle"
                      id="seoTitle"
                      value={formData.seoTitle}
                      onChange={handleChangeForm}
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label for="seoKeywords" sm={6}>
                    Key Words<b className="text-danger">*</b>
                  </Label>
                  <Col sm={6}>
                    <Input
                      type="text"
                      name="seoKeyword"
                      id="seoKeyword"
                      value={formData.seoKeyword}
                      onChange={handleChangeForm}
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label for="seoDescription" sm={6}>
                    Description<b className="text-danger">*</b>
                  </Label>
                  <Col sm={6}>
                    <Input
                      type="textarea"
                      name="seoDescription"
                      id="seoDescription"
                      value={formData.seoDescription}
                      onChange={handleChangeForm}
                    />
                  </Col>
                </FormGroup>
              </div>
              <FormGroup row>
                <div className="text-end my-3">
                  <Button color="danger" type="submit" disabled={loading}>
                    Submit
                  </Button>
                </div>
              </FormGroup>
            </Form>
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default CreateGurdwara;
