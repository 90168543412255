import React, { useState, useEffect } from "react";
import ProductCard from "./ProductCard";
import SmallScreenProducts from "./SmallScreenProducts";
import TvScreenProducts from "./TvScreenProducts";
import { FadeLoader } from "react-spinners";

const ProductSection = ({ data, subCategories }) => {
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [tvScreen, setTvScreen] = useState(false);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const updateItemsPerPage = () => {
    const screenWidth = window.innerWidth;
    console.log("Screen Width:", screenWidth);
    switch (true) {
      case screenWidth >= 1200:
        setTvScreen(true);
        break;
      case screenWidth >= 768:
        setItemsPerPage(8 * 4);
        setTvScreen(false);
        break;
      case screenWidth >= 576:
        setItemsPerPage(6 * 4);
        setTvScreen(false);
        break;
      default:
        setTvScreen(false);
        break;
    }
    setIsInitialLoad(false);
  };
  useEffect(() => {
    updateItemsPerPage();
    window.addEventListener("resize", updateItemsPerPage);
    return () => window.removeEventListener("resize", updateItemsPerPage);
  }, []);

  if (isInitialLoad) {
    return (
      <FadeLoader
        color={"#36D7B7"}
        loading={true}
        className="top-100 start-100 translate-middle"
      />
    ); // Render nothing or a loading indicator
  }
  return (
    <div>
      {tvScreen ? (
        <TvScreenProducts data={data} subCategories={subCategories} />
      ) : (
        <SmallScreenProducts
          data={data}
          itemsPerPage={itemsPerPage}
          tvScreen={tvScreen}
        />
      )}
    </div>
  );
};

export default ProductSection;
