import React, { useEffect, useState } from "react";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { Label, Input } from "reactstrap";
import {
  AdPaymentUpdate,
  fetchSettings,
  reciveOrder,
} from "../../../components/Header/Data2";
import { useNavigate, useLocation } from "react-router-dom";
import ThankYouModal from "../Orders/ThankYouPage";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { FadeLoader } from "react-spinners";
import {
  createGurudwaraBooking,
  Featured,
  viewPhonePayment,
} from "../../../components/Header/Data";
import { setFeatureService } from "../../../redux/slices/PaymentSlice";
import axiosClient from "../../../axios-client";
import { getMyCartItems } from "../../../components/Header/MyCart";
import {
  setPaymentStatus,
  setResponseId,
} from "../../../redux/slices/GurdwaraSlice";

const Payment = ({ totalPrice, adDetails, state }) => {
  const paymentSlice = useSelector((state) => state.payment);
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const gurudwaraId = parseInt(queryParams.get("id"), 10); // Keep
  const islogin = useSelector((state) => state.user.isLogin);
  const userinfo = useSelector((state) => state.user.userInfo);
  const user = islogin ? JSON.parse(userinfo) : null;
  const [thankYou, setThankYou] = useState(false);
  // const [orderDetails, setOrderDetails] = useState([]);
  const [clientId, setClientId] = useState("");
  const logUser = localStorage.getItem("USER_ROLE");
  console.log(paymentSlice.featureService, "FeatureService");
  const dispatch = useDispatch();
  const { selectedServicePrice, responseId, termsConditions, paypalClientID } =
    useSelector((state) => state.gurdwara);
  const fetchSet = async () => {
    if (state?.bookingGurdwara && paypalClientID) {
      setClientId(paypalClientID); // Use the paypalClientID from the state
    } else {
      const settings = await fetchSettings();
      const filterSet = settings.filter((set) => set.setting_name === "paypal");
      setClientId(filterSet[0]?.client_id); // Default fetch logic if not bookingGurdwara
    }
  };
  useEffect(() => {
    fetchSet();
  }, [adDetails, state?.bookingGurdwara, paypalClientID]);
  console.log(clientId, "paypalClientID");
  const placeOrder = async (details) => {
    const orderData = localStorage.getItem("ORDER_DETAILS");
    const orderDetails = orderData ? JSON.parse(orderData) : null;
    if (orderDetails && orderDetails.Id) {
      // navigate(`/feedBack/${orderDetails.Id}`);
      let formData = {
        // details,
        status: details.status,
        messageId: state.selectedConversation
          ? state.selectedConversation.Id
          : 0,
      };
      await reciveOrder(formData, orderDetails.Id); // Access order id directly
      toggleModal();
      localStorage.setItem("ORDER_DETAILS", null);
    } else {
      console.error("Order details are missing or invalid.");
    }
  };

  const handleOfferPayment = async (details) => {
    if (state?.reqPhoneAmount) {
      const id = state.msg.Id;
      let formData = {
        senderId: logUser === "provider" ? user?.UserID : user?.UserID,
        requestPhone: logUser === "provider" ? "provider" : "customer",
        paypalResponse: details,
        //createBy : user.UserID === 4 ? 4 : 5,
        //serviceNeed : state?.msg?.serviceNeed,
        //totalAmount:state.reqPhoneAmount,
        //Discount:0,
        //paidAmount:state.reqPhoneAmount,
        //orderStatus:state?.msg?.orderStatus,
      };
      console.log(formData, "formData");
      await viewPhonePayment(formData, id);
    }
  };
  const updateItemOrder = async (details) => {
    if (state?.item) {
      const orderData = localStorage.getItem("ITEM_ORDER_DETAILS");
      const orderDetails = orderData ? JSON.parse(orderData) : null;
      if (orderDetails && orderDetails.id) {
        let formData = {
          orderStatus: details.status,
          cartId: state.itemOrder?.cart_id,
          itemId: state.itemOrder?.item_id,
          updatedBy: user.UserID,
        };
        try {
          const response = await axiosClient.put(
            `item/StatusUpdateItemOrder/${orderDetails.id}`,
            formData
          );
          if (response.data.statusCode === 200) {
            toast.success("Order Placed");
            setTimeout(() => {
              getMyCartItems(user.UserID, dispatch);
            }, 1000);
          }
        } catch {
          toast.error("Payment Incomplete");
        }
        toggleModal();
        localStorage.setItem("ITEM_ORDER_DETAILS", null);
      } else {
        console.error("Order details are missing or invalid.");
      }
    }
  };
  const SponsordAdPayment = async (details) => {
    if (adDetails) {
      const request = {
        createdBy: user.UserID,
        paymentStatus: details.status,
      };
      await AdPaymentUpdate(adDetails.id, request);

      toast.success("Payment Successfull");
    }
  };
  const FeaturingService = async (details) => {
    if (paymentSlice.featureService !== null) {
      const reqData = {
        featuredListing: 1,
        updatedBy: user.UserID,
      };
      await Featured(reqData, paymentSlice.featureService.Id);
      dispatch(setFeatureService(null));
      toggleModal();
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    }
  };
  console.log(state, "state");
  const toggleModal = () => {
    setThankYou(!thankYou);
  };

  if (!clientId) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          margin: "50px",
        }}
      >
        <FadeLoader color="#36d7b7" />
      </div>
    ); // Or any loading indicator you prefer
  }
  const bookingPaymentStatus = async (details) => {
    if (state?.bookingGurdwara) {
      console.log(details.status, "detailsStatus");
      let formData = {
        paymentStatus: details.status,
        Amount: selectedServicePrice,
        updatedBy: user?.UserID,
        paymentDetails: details,
        gurudwaraId: gurudwaraId,
        Id: responseId,
      };
      const response = await createGurudwaraBooking(formData);
      console.log("response", response);
      dispatch(setPaymentStatus(details?.status));
    }
  };
  console.log(termsConditions, "termsConditions");
  return (
    <div
      className={`${totalPrice === 10 && "w-50"} mt-5`}
      style={{ marginLeft: totalPrice === 10 && "25%" }}
    >
      <PayPalScriptProvider
        options={{
          "client-id": clientId,
          // "client-id":
          //   "AaqY5POsphq_iOg3JGN-H5lxMige1F_HmVlqMiu_sR4RrJzYLg_B8gqjVX1n-0pOlWPe5upN2Zb3q_RU",
          // "client-id": process.env.PAYPAL_CLIENT_ID,
        }}
      >
        <PayPalButtons
          disabled={state?.bookingGurdwara ? termsConditions != 1 : false}
          createOrder={(data, actions) => {
            return actions.order.create({
              purchase_units: [
                {
                  amount: {
                    value: totalPrice,
                  },
                },
              ],
            });
          }}
          onApprove={(data, actions) => {
            return actions.order.capture().then((details) => {
              console.log("=========", JSON.stringify(details, null, 4));
              console.log("=========22", details.status);
              SponsordAdPayment(details);
              placeOrder(details);
              handleOfferPayment(details);
              FeaturingService(details);
              updateItemOrder(details);
              bookingPaymentStatus(details);
            });
          }}
          onError={(error) => {
            console.error("PayPal error", error);
          }}
        />
        <ThankYouModal
          show={thankYou}
          onHide={toggleModal}
          totalPrice={totalPrice}
          state={state}
        />
      </PayPalScriptProvider>
    </div>
  );
};
export default Payment;
