import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  fullName: "",
  email: "",
  serviceType: "",
  mobileNumber1: null,
  mobileNumber2: null,
  mobileNumber3: null,
  eventDate: null,
  eventTime: null,
  eventLocation: "",
  additionalPeople: "",
  currentStep: 1,
  gurdwaras: [],
  termsConditions: 0,
  selectedGurdwara: null,
  serviceTypes: [],
  servicePrices: {},
  selectedServicePrice: "",
  selectedServiceType: null,
  detailGurdwara: "",
  responseId: null,
  paymentStatus: "",
  paypalID: "",
  terms: "",
  tollFreeNumber: null,
  selectedDate: null,
  selectedTime: null,
  timezone: "America/Los_Angeles, Pacific Time",
  serviceInfo: "",
  servieAvailableDays: {},
  serviceAvailableTime: {},
  serviceDurations: {},
  serviceCounts: {},
  selectedserviceAvaialableDays: [],
  selectedserviceAvaialableTime: {},
  selectedserviceAvaialableDuration: "",
  selectedserviceCount: "",
  paypalClientID: "",
  paypalSecretKey: "",
};

const gurdwraSlice = createSlice({
  name: "gurdwra",
  initialState,
  reducers: {
    resetGurdwara(state, action) {
      state.fullName = "Customers";
      state.email = "";
      state.serviceType = "";
      state.mobileNumber1 = null;
      state.mobileNumber2 = null;
      state.mobileNumber3 = null;
      state.eventDate = null;
      state.eventFrom = null;
      state.eventTo = null;
      state.additionalPeople = "";
    },
    setFullName(state, action) {
      state.fullName = action.payload;
    },
    setEmail(state, action) {
      state.email = action.payload;
    },
    setServiceType(state, action) {
      state.serviceType = action.payload;
    },
    setMobileNumber1(state, action) {
      state.mobileNumber1 = action.payload;
    },
    setMobileNumber2(state, action) {
      state.mobileNumber2 = action.payload;
    },
    setMobileNumber3(state, action) {
      state.mobileNumber3 = action.payload;
    },
    setEventDate(state, action) {
      state.eventDate = action.payload;
    },
    setEventTime(state, action) {
      state.eventTime = action.payload;
    },
    setEventLocation(state, action) {
      state.eventLocation = action.payload;
    },
    setAdditionalPeople(state, action) {
      state.additionalPeople = action.payload;
    },
    setCurrentStep(state, action) {
      state.currentStep = action.payload;
    },
    setGurdwaras(state, action) {
      state.gurdwaras = action.payload;
    },
    setSelectedGurdwara(state, action) {
      state.selectedGurdwara = action.payload;
    },
    setServiceTypes(state, action) {
      state.serviceTypes = action.payload;
    },
    setSelectedServicePrice(state, action) {
      state.selectedServicePrice = action.payload;
    },
    setServicePrices(state, action) {
      state.servicePrices = action.payload;
    },
    setSelectedServiceType(state, action) {
      state.selectedServiceType = action.payload;
    },
    setDetailGurdwara(state, action) {
      state.detailGurdwara = action.payload;
    },
    setTermsConditions(state, action) {
      state.termsConditions = action.payload;
    },
    setResponseId(state, action) {
      state.responseId = action.payload;
    },
    setPaymentStatus(state, action) {
      state.paymentStatus = action.payload;
    },
    setPaypalID(state, action) {
      state.paypalID = action.payload;
    },
    setTollFreeNumber(state, action) {
      state.tollFreeNumber = action.payload;
    },
    setTerms(state, action) {
      state.terms = action.payload;
    },
    setSelectedDate(state, action) {
      state.selectedDate = action.payload;
    },
    setSelectedTime(state, action) {
      state.selectedTime = action.payload;
    },
    setTimeZone(state, action) {
      state.timezone = action.payload;
    },
    setServiceInfo(state, action) {
      state.serviceInfo = action.payload;
    },
    setServieAvailableDays(state, action) {
      state.servieAvailableDays = action.payload;
    },
    setServiceAvailableTime(state, action) {
      state.serviceAvailableTime = action.payload;
    },
    setSelectedserviceAvaialableDays(state, action) {
      state.selectedserviceAvaialableDays = action.payload;
    },
    setSelectedserviceAvaialableTime(state, action) {
      state.selectedserviceAvaialableTime = action.payload;
    },
    setServiceDurations(state, action) {
      state.serviceDurations = action.payload;
    },
    setSelectedserviceAvaialableDuration(state, action) {
      state.selectedserviceAvaialableDuration = action.payload;
    },
    setServiceCounts(state, action) {
      state.serviceCounts = action.payload;
    },
    setSelectedserviceCount(state, action) {
      state.selectedserviceCount = action.payload;
    },
    setPayPalClientID(state, action) {
      state.paypalClientID = action.payload;
    },
    setPayPalSecretKey(state, action) {
      state.paypalSecretKey = action.payload;
    },
  },
});

export const {
  resetGurdwara,
  setFullName,
  setEmail,
  setServiceType,
  setMobileNumber1,
  setMobileNumber2,
  setMobileNumber3,
  setEventDate,
  setEventTime,
  setEventLocation,
  setAdditionalPeople,
  setCurrentStep,
  setGurdwaras,
  setSelectedGurdwara,
  setServiceTypes,
  setSelectedServicePrice,
  setServicePrices,
  setSelectedServiceType,
  setDetailGurdwara,
  setTermsConditions,
  setResponseId,
  setPaymentStatus,
  setPaypalID,
  setTollFreeNumber,
  setTerms,
  setSelectedDate,
  setSelectedTime,
  setTimeZone,
  setServiceInfo,
  setServieAvailableDays,
  setServiceAvailableTime,
  setServiceDurations,
  setServiceCounts,
  setSelectedserviceAvaialableDays,
  setSelectedserviceAvaialableTime,
  setSelectedserviceAvaialableDuration,
  setSelectedserviceCount,
  setPayPalClientID,
  setPayPalSecretKey,
} = gurdwraSlice.actions;

export default gurdwraSlice.reducer;
